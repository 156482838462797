import React, { useState } from "react";
import linkedinIcon from "../../assets/linkedinIcon.svg";
import BuildOppReminderBG1 from "../../assets/build_opp_card_bg1.png";
import { trackAnalytics } from "../../analytics/analyticsApi";
import { useSelector } from "react-redux";
import { commonProperties } from "../../hooks/useCommonProperties";

type BuildOpportunityReminderProps = {
  cardType: 1 | 2;
  setShowBuildOpportunityReminder: Function;
  showBuildOpportunityReminder?: boolean;
  handleCloseBeforeModal: Function;
};

const BuildOpportunityReminder = ({
  cardType,
  showBuildOpportunityReminder,
  setShowBuildOpportunityReminder,
  handleCloseBeforeModal,
}: BuildOpportunityReminderProps) => {
  const meetingInfo = useSelector((state: any) => state.meetingInfo);
  const [isMarkAsReminded, setIsMarkAsReminded] = useState(false);

  const getRequestBody = (args: string) => {
    return {
      user_id:
        meetingInfo.role === "Mentor"
          ? meetingInfo.mentorEmail
          : meetingInfo.menteeEmail,
      event: "LinkedIn WoM Reminder Sent",
      properties: {
        ...commonProperties(meetingInfo),
        action_by: meetingInfo.role.toString(),
        type: args,
        mentee: {
          name: meetingInfo?.menteeName,
          id: meetingInfo?.menteeId,
          email: meetingInfo?.menteeEmail,
        },
        mentor: {
          name: meetingInfo?.mentorName,
          id: meetingInfo?.mentorId,
          email: meetingInfo?.mentorEmail,
        },
      },
    };
  };

  const sendAnalyticsForReminded = () => {
    try {
      const requestBody = getRequestBody("mentee_applied");
      trackAnalytics(requestBody);
    } catch (err) {
      console.error(
        "Error In function  for event Name is",
        `LinkedIn WoM Reminder Sent`,
        err
      );
    }
  };

  const handleMarkAsReminded = () => {
    sendAnalyticsForReminded();
    setIsMarkAsReminded(true);
    setTimeout(() => {
      setShowBuildOpportunityReminder(false);
      handleCloseBeforeModal();
    }, 2000);
  };

  const sendAnalyticsForMenteeInLoop = () => {
    try {
      const requestBody = getRequestBody("mentee_not_applied");
      trackAnalytics(requestBody);
    } catch (err) {
      console.error(
        "Error In function  for event Name is",
        `LinkedIn WoM Reminder Sent`,
        err
      );
    }
    setShowBuildOpportunityReminder(false);
    handleCloseBeforeModal();
  };

  const handleClickLearnMore = () => {
    setShowBuildOpportunityReminder(false);
    handleCloseBeforeModal();
  };

  return (
    <div className="relative flex flex-col items-start gap-5 self-stretch pt-6 pb-4 px-6 opacity-[0.9] rounded-lg border border-[#e9d7fe] bg-white/[.20] mt-6">
      <img
        src={BuildOppReminderBG1}
        alt="bg1"
        className={`absolute top-0 right-0 z-[-1] max-h-[184px] w-full opacity-[0.2] ${
          isMarkAsReminded && `h-[128px]`
        } `}
      />
      <div className="flex flex-col items-start gap-3 self-stretch">
        <div className="flex items-center">
          <img
            src={linkedinIcon}
            alt=""
            className="w-6 h-6 mr-2"
          />
          <h2 className="text-[#344054] font-semibold leading-[normal]">
            {cardType === 1
              ? `Remind your mentee to post about this session!`
              : `Talk about ‘Build Opportunities’ 🚀`}
          </h2>
        </div>
        {!isMarkAsReminded && (
          <p className="self-stretch text-[#667085] text-sm leading-[1.375rem]">
            {cardType === 1
              ? `Ask your mentee to make a small note of what they learnt. We will help them write up a post on LinkedIn and tag`
              : `So we can help them share their growth and session learnings on LinkedIn and `}
            <span className="font-semibold text-[#344054] ">
              {" "}
              {cardType === 1 ? ` you in it 🔥 ` : ` tag you in them! `}
            </span>
          </p>
        )}
      </div>
      <div className="flex justify-between w-full items-start gap-2 self-stretch">
        {cardType === 2 ? (
          <a
            className=" w-full flex justify-center items-center gap-2 py-3 px-4 rounded border border-[#d0d5dd] bg-white text-[#344054] text-center text-sm font-medium leading-[1.125rem]"
            href={
              "https://preplaced.notion.site/Introducing-Build-Opportunities-in-Long-Term-Mentorship-60866efaae10411eaad8f022e5f794e3"
            }
            onClick={() => handleClickLearnMore()}
            target="_blank"
            rel="noreferrer"
          >
            Learn More
          </a>
        ) : null}
        {cardType === 1 ? (
          <button
            className={`w-full flex justify-center items-center gap-2 py-3 px-4 rounded border ${
              isMarkAsReminded
                ? `text-[#12B76A] border-[#A6F4C5] bg-white`
                : `border-[#03020a] bg-[#03020a] text-white`
            } text-center text-sm font-medium leading-[1.125rem]`}
            onClick={isMarkAsReminded ? ()=>(console.log("Reminded")) : handleMarkAsReminded}
          >
            {isMarkAsReminded ? `Marked as Reminded` : `Mark as Reminded `}
          </button>
        ) : (
          <button
            className="w-full flex justify-center items-center gap-2 py-3 px-4 rounded border border-[#03020a] bg-[#03020a] text-white text-center text-sm font-medium leading-[1.125rem]"
            onClick={() => sendAnalyticsForMenteeInLoop()}
          >
            {`Mentee is in loop :)`}
          </button>
        )}
      </div>
    </div>
  );
};

export default BuildOpportunityReminder;
