import React from "react";
import "./App.css";
import { MainScreenComponent } from "./routes/root/main";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import customTheme from "./customTheme";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import OnboardMeeting from "./routes/join-meeting/join-meeting";
import SupportButton from "./components/SupportButton/SupportButton";

const App = () => {
  return (
    <React.Fragment>
      <Provider store={store}>
        <ChakraProvider theme={customTheme}>
          <SupportButton />
          <Router>
            <Switch>
              <Route
                path="/join-meeting/:room/:id"
                component={OnboardMeeting}
              />
              <Route path="/" component={MainScreenComponent} />
            </Switch>
          </Router>
        </ChakraProvider>
      </Provider>
    </React.Fragment>
  );
};

export default App;
