import { createSlice } from "@reduxjs/toolkit";

export interface MeetingInfoState {
  authToken: string;
  menteeName: string;
  menteeId: string;
  menteeEmail: string;
  menteePhotoUrl: string;
  feedbackToBeFilledByCandidate: string;
  feedbackToBeFilledByMentor: string;
  isFeedbackSubmittedByMentee: boolean;
  isFeedbackSubmittedByMentor: boolean;
  mentorName: string;
  mentorEmail: string;
  mentorId: string;
  mentorPhotoUrl: string;
  myInterviewDocLink: string;
  plannerLink: string;
  role: string;
  sessionType: string;
  sessionId: string;
  meetingStatus: string;
  sessionName: string;
  packageType: string;
  packageName: string;
  sessionTitle: string;
  packageId: string;
  device: any;
  relationshipManagerEmail: string;
  relationshipManagerId: string;
  relationshipManagerName: string;
  relationshipManagerPhoneNumber: string;
  currentScreen: string;
  isRecurring: boolean;
}

const initialState: MeetingInfoState = {
  authToken: "",
  menteeName: "",
  menteeId: "",
  menteeEmail: "",
  menteePhotoUrl: "",
  feedbackToBeFilledByCandidate: "",
  feedbackToBeFilledByMentor: "",
  isFeedbackSubmittedByMentee: false,
  isFeedbackSubmittedByMentor: false,
  mentorName: "",
  mentorEmail: "",
  mentorId: "",
  mentorPhotoUrl: "",
  myInterviewDocLink: "",
  plannerLink: "",
  role: "",
  sessionType: "",
  sessionId: "",
  meetingStatus: "Pending",
  sessionName: "",
  packageType: "",
  packageName: "",
  sessionTitle: "",
  packageId: "",
  device: {
    isMobile: null,
    browserName: "",
    osName: "",
    browserVersion: "",
    osVersionName: "",
    engineName: "",
  },
  relationshipManagerEmail: "",
  relationshipManagerId: "",
  relationshipManagerName: "",
  relationshipManagerPhoneNumber: "",
  currentScreen: "Onboarding",
  isRecurring: false,
};

const setActionsToReduxState = (state: any, action: any) => {
  const {
    authToken,
    menteeName,
    menteeEmail,
    menteeId,
    menteePhotoUrl,
    menteePhoneNumber,
    feedbackToBeFilledByCandidate,
    feedbackToBeFilledByMentor,
    mentorName,
    mentorEmail,
    mentorId,
    mentorPhotoUrl,
    mentorPhoneNumber,
    myInterviewDocLink,
    plannerLink,
    role,
    sessionType,
    sessionId,
    sessionTitle,
    packageType,
    packageName,
    relationshipManagerEmail,
    relationshipManagerId,
    relationshipManagerName,
    relationshipManagerPhoneNumber,
    packageId,
    isFeedbackSubmittedByMentee,
    isFeedbackSubmittedByMentor,
    isRecurring,
    recordingStatus,
    isV1Meet,
    plan,
    sessionCount,
  } = action.payload;
  state.authToken = authToken || "";
  state.menteeName = menteeName;
  state.menteeEmail = menteeEmail;
  state.menteeId = menteeId;
  state.menteePhotoUrl = menteePhotoUrl;
  state.menteePhoneNumber = menteePhoneNumber
  state.feedbackToBeFilledByCandidate = feedbackToBeFilledByCandidate;
  state.feedbackToBeFilledByMentor = feedbackToBeFilledByMentor;
  state.mentorName = mentorName;
  state.mentorEmail = mentorEmail;
  state.mentorId = mentorId;
  state.mentorPhotoUrl = mentorPhotoUrl;
  state.mentorPhoneNumber = mentorPhoneNumber;
  state.myInterviewDocLink = myInterviewDocLink;
  state.plannerLink = plannerLink;
  state.role = role;
  state.sessionType = sessionType;
  state.sessionId = sessionId;
  state.packageName = packageName;
  state.sessionTitle = sessionTitle;
  state.packageType = packageType;
  state.relationshipManagerEmail = relationshipManagerEmail;
  state.relationshipManagerId = relationshipManagerId;
  state.relationshipManagerName = relationshipManagerName;
  state.relationshipManagerPhoneNumber = relationshipManagerPhoneNumber;
  state.packageId = packageId;
  state.isFeedbackSubmittedByMentee = isFeedbackSubmittedByMentee;
  state.isFeedbackSubmittedByMentor = isFeedbackSubmittedByMentor;
  state.isRecurring = isRecurring;
  state.recordingStatus = recordingStatus;
  state.isV1Meet = isV1Meet;
  state.plan = plan;
  state.sessionCount = sessionCount;
};

export const meetingInfoSlice = createSlice({
  name: "meetingInfo",
  initialState,
  reducers: {
    setMeetingInfo: (state, action) => {
      setActionsToReduxState(state, action);
    },

    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },

    setRole: (state, action) => {
      setActionsToReduxState(state, action);
    },

    setFeedbackForm: (state, action) => {
      const { mentorFeedbackFormURL, menteeFeedbackFormURL } = action.payload;
      state.feedbackToBeFilledByCandidate = menteeFeedbackFormURL;
      state.feedbackToBeFilledByMentor = mentorFeedbackFormURL;
    },
    setMeetingStatus: (state, action) => {
      state.meetingStatus = action.payload.meetingStatus;
      state.currentScreen = action.payload.currentScreen;
    },
    setDeviceInfo: (state, action) => {
      const {
        browserName,
        browserVersion,
        engineName,
        isMobile,
        osName,
        osVersionName,
      } = action.payload;
      state.device.isMobile = isMobile;
      state.device.browserName = browserName;
      state.device.browserVersion = browserVersion;
      state.device.engineName = engineName;
      state.device.osName = osName;
      state.device.osVersionName = osVersionName;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setMeetingInfo,
  setAuthToken,
  setRole,
  setFeedbackForm,
  setMeetingStatus,
  setDeviceInfo,
} = meetingInfoSlice.actions;

export default meetingInfoSlice.reducer;
