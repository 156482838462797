// import bellWarn from "../../assets/bellWarn.svg";
// import PromptComponent from "../prompt-comp/prompt-comp";
import useScheduleSessionPromptHook from "./use-schedule-session-prompt-hook";
import BuildOppReminderBG2 from "../../assets/build_opp_card_bg2.png";


const ScheduleSessionPrompt = ({ hideScheduleSessionPopUp }: any) => {
  const { handlerPrimaryButton, handlerSecondaryButton } =
    useScheduleSessionPromptHook(hideScheduleSessionPopUp);
  return (

    <div className="relative flex flex-col items-start gap-5 self-stretch pt-6 pb-4 px-6 opacity-[0.9] rounded-lg border border-[#b9e6fe] bg-white/[.20] mt-6">
      <img src={BuildOppReminderBG2} alt="bg1" className='absolute top-0 right-0 z-[-1] max-h-[128px] w-full opacity-[0.2]' />
      <h2 className="text-[#344054] font-semibold">
        🗓️  Schedule your next session
      </h2>
      <div className="flex justify-between items-start gap-2 self-stretch">
        <button className="buttons-1 flex justify-center items-center gap-2 py-3 px-4 rounded border border-[#d0d5dd] bg-white text-[#344054] text-center text-sm font-medium leading-[1.125rem]" onClick={handlerPrimaryButton}>
          Set only Next Session
        </button>
        <button className="buttons-2 flex justify-center items-center gap-2 py-3 px-4 rounded border border-[#03020a] bg-[#03020a] text-white text-center text-sm font-medium leading-[1.125rem]" onClick={handlerSecondaryButton}>
          Set a Weekly Schedule
        </button>
      </div>
    </div>
  );
};

export default ScheduleSessionPrompt;
