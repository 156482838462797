import { extendTheme } from "@chakra-ui/react";

const colors = {
  primary: {
    500: "#0C5BF3",
  },
  red: {
    500: "#F34956",
  },
  gray: {
    700: "#374151",
    800: "#181818",
  },
  zinc: {
    500: "#71717A",
    600: "#C5C5C536",
    700: "#3F3F46",
    800: "#27272A",
  },
};

const customTheme = extendTheme({ components: { Button: { baseStyle: { _focus: { boxShadow: 'none' } } } },colors })

export default customTheme;