import {
  DyteParticipantsAudio,
  DyteNotifications,
  DyteDialogManager,
} from "@dytesdk/react-ui-kit";
import { Flex } from "@chakra-ui/react";
import { useDyteSelector, useDyteMeeting } from "@dytesdk/react-web-core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { trackAnalytics } from "../../analytics/analyticsApi";
import Header from "../Header/Header";
import DyteContent from "../DyteContent/DyteContent";
import Footer from "../Footer/Footer";
import JoinMeeting from "../JoinMeeting/JoinMeeting";
import { commonProperties } from "../../hooks/useCommonProperties";
import { PreplacedCall } from "preplaced-call-service";
import useOngoingMeeting from "./use-ongoing-meeting";

const MeetingRender = ({
  duration,
  showOfflinePhoneCallPopUp,
  hideOfflinePhoneCallPrompt,
  showInstantCallPopUp,
  hideInstantCallPrompt,
}: any) => {
  //Initializing Preplaced Call Service
  const {} = useOngoingMeeting();

  // Component States
  const [showChatContainer, setshowChatContainer] = useState(false);
  const [states, setStates] = useState<any>({});

  const allPlugins = useDyteSelector((m) => m.plugins.all);

  const codeRoughWork: any = allPlugins?.get(
    // "54c140a4-e4ff-402b-85d1-ce23182ad3e3"
    "40b0d43f-388b-4232-b918-c2d900a7d498"
  );

  const whiteBoard: any = allPlugins?.get(
    // "49f9e74a-bf03-4aac-a9c2-3db9f8fdfa41"
    "ae79b269-24ca-4f8a-8112-f96084c8c19a"
  );

  const meetingInfo = useSelector((state: any) => state.meetingInfo);

  const { meeting } = useDyteMeeting();

  (window as any).dyteMeeting = meeting;

  const roomJoined = useDyteSelector((m) => m.self.roomJoined);

  const toggleDyteChat = () => {
    setshowChatContainer(!showChatContainer);
  };

  useEffect(() => {
    (meeting as any).self.on(
      "screenShareUpdate",
      async ({ screenShareEnabled, screenShareTracks }: any) => {
        if (meeting) {
          if (screenShareEnabled) {
            codeRoughWork.deactivate();
            whiteBoard.deactivate();
            /* -------------------------------------------------------------------------- */
            /*                          Analytics: Screen Shared                          */
            /* -------------------------------------------------------------------------- */
            const reqBodyScreenShareStarted = {
              user_id:
                meetingInfo.role === "Mentor"
                  ? meetingInfo.mentorEmail
                  : meetingInfo.menteeEmail,
              event: "Screen Share Started",
              properties: {
                ...commonProperties(meetingInfo),
                ...meeting.self.device,
                started_by: meetingInfo.role,
              },
            };
            trackAnalytics(reqBodyScreenShareStarted);
          } else {
            /* -------------------------------------------------------------------------- */
            /*                        Analytics: Screen Shared Stop                       */
            /* -------------------------------------------------------------------------- */
            const reqBodyScreenShareClosed = {
              user_id:
                meetingInfo.role === "Mentor"
                  ? meetingInfo.mentorEmail
                  : meetingInfo.menteeEmail,
              event: "Screen Share Closed",
              properties: {
                ...commonProperties(meetingInfo),
                ...meeting.self.device,
                closed_by: meetingInfo.role,
              },
            };
            trackAnalytics(reqBodyScreenShareClosed);
          }
        }
      }
    );
  }, [meeting]);

  if (!roomJoined) {
    return <JoinMeeting />;
  }

  return (
    <>
      <Flex
        gap={"10px"}
        flexDirection="column"
        h="100%"
        w="100%"
        color="white"
        justifyContent="space-between"
      >
        <Header
          meeting={meeting}
          duration={duration}
          showChatContainer={showChatContainer}
          toggleDyteChat={toggleDyteChat}
        />

        <DyteContent
          meeting={meeting}
          showChatContainer={showChatContainer}
          states={states}
          onDyteStateUpdate={(s: any) => setStates({ ...states, ...s.detail })}
          duration={duration}
          showOfflinePhoneCallPopUp={showOfflinePhoneCallPopUp}
          hideOfflinePhoneCallPrompt={hideOfflinePhoneCallPrompt}
          showInstantCallPopUp={showInstantCallPopUp}
          hideInstantCallPrompt={hideInstantCallPrompt}
        />

        <Footer
          meeting={meeting}
          showChatContainer={showChatContainer}
          toggleDyteChat={toggleDyteChat}
        />
      </Flex>
      <PreplacedCall />
      <DyteNotifications
        meeting={meeting}
        config={{
          config: {
            notifications: {
              participant_joined: true,
              participant_left: true,
              chat: true,
            },
            notification_sounds: {
              participant_joined: true,
              participant_left: true,
              chat: true,
            },

            participant_joined_sound_notification_limit: 10,
            participant_chat_message_sound_notification_limit: 10,
          },
        }}
      />
      <DyteParticipantsAudio meeting={meeting} />
      <DyteDialogManager meeting={meeting} />
    </>
  );
};

export default MeetingRender;
