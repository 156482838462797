import { useState } from 'react';

const useFeedbackTrial = () => {
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
  const [isFeedbackCanceled, setIsFeedbackCanceled] = useState(false);
  const [textareaValue, setTextareaValue] = useState<string>('');
  const [filledStarsVideo, setFilledStarsVideo] = useState<number>(0);
  const [filledStarsAudio, setFilledStarsAudio] = useState<number>(0);

  const handleFilledStarsVideo = (index: number) => {
    setFilledStarsVideo(index + 1);
  };

  const handleFilledStarsAudio = (index: number) => {
    setFilledStarsAudio(index + 1);
  };

  const handleTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setTextareaValue(event.target.value);
  };

  const handleFeedbackSubmit = () => {
    setIsFeedbackSubmitted(true);
    console.log('textareaValue', textareaValue);
    console.log('filledStarsVideo', filledStarsVideo);
    console.log('filledStarsAudio', filledStarsAudio);
  };

  const handleCancelFeedback = () => {
    setIsFeedbackCanceled(true);
  };

  return {
    isFeedbackSubmitted,
    setIsFeedbackSubmitted,
    handleFeedbackSubmit,
    handleCancelFeedback,
    isFeedbackCanceled,
    handleTextareaChange,
    textareaValue,
    filledStarsVideo,
    handleFilledStarsVideo,
    filledStarsAudio,
    setFilledStarsAudio,
    handleFilledStarsAudio,
  };
};

export default useFeedbackTrial;
