import { Button, useBreakpointValue } from '@chakra-ui/react'
import React from 'react'

type SendMentorshipButtonProps = {
  handleMentorshipPlanPopup: Function
}

const SendMentorshipButton = ({ handleMentorshipPlanPopup }: SendMentorshipButtonProps) => {
  const buttonWidth = useBreakpointValue({ base: '180px', md: '220px' }); // Adjust width based on breakpoints

  return (
    <>
      <Button
        padding="0 20px"
        borderRadius="4px"
        fontSize="14px"
        fontWeight="600"
        backgroundColor="#1570EF"
        outline="none"
        color="#FFF"
        onClick={() => { handleMentorshipPlanPopup() }}
        height="32px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          width: buttonWidth,
          '&:hover': {
            backgroundColor: "#0e4ea7",
          },
        }}
      >
        Send Mentorship Plan
      </Button>
    </>
  )
}

export default SendMentorshipButton