import {
  DyteCameraToggle,
  DyteLeaveButton,
  DyteMicToggle,
  DyteSettingsToggle,
  DyteScreenShareToggle,
  DyteControlbarButton,
  DyteRecordingToggle,
} from "@dytesdk/react-ui-kit";
import DyteVideoBackgroundTransformer from "@dytesdk/video-background-transformer";
import { Box, Button, Flex, Hide, Show } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useDyteSelector } from "@dytesdk/react-web-core";
import { ReactComponent as BlurIcon } from "../../assets/blur-icon.svg";
import { ReactComponent as CallIcon } from "../../assets/call-icon-white.svg";
import {
  arrowDownIcon,
  arrowUpIcon,
  closeIcon,
  codeRoughWorkIcon,
  plannerIcon,
  whiteBoardIcon,
  aiLearningIcon,
} from "../icons/icons";
import { useSelector } from "react-redux";
import { trackAnalytics } from "../../analytics/analyticsApi";
import { commonProperties } from "../../hooks/useCommonProperties";
import ChatButton from "../chat-button/chat-button";
import useUnreadMessages from "./use-footer-hook";
import LeaveButton from "../LeaveButton/leave-button";
import { VideoMiddleware } from "@dytesdk/video-background-transformer/types/common/BackgroundTransformer";
import CallButton from "../call-button/call-button";
import useCallButton from "../call-button/use-call-button";
import { PreplacedCallButton } from "preplaced-call-service";

interface FooterProps {
  meeting?: any;
  showChatContainer: boolean;
  toggleDyteChat: () => void;
}

const aiLearningAllowedMentors = [
  "malaybhayani1994@gmail.com",
  "anarghyakini@gmail.com",
  "mahajan.20chetan@gmail.com",
  "m4manishpushkar@gmail.com",
  "aishitdharwal@gmail.com",
  "neeteshmittal.nita@gmail.com",
  "vikas2cool18@gmail.com",
  "syedsamer456@gmail.com",
  "angaddubey10@gmail.com",
  "sdas.rng@gmail.com",
  "akshaysharma.iimk@gmail.com",
  "ruhi2820@gmail.com",
];

const isAiLearningAllowed = (email: string) => {
  return aiLearningAllowedMentors?.includes(email);
};

const Footer = ({
  meeting,
  showChatContainer,
  toggleDyteChat,
}: FooterProps) => {
  const [isOpen, setisOpen] = useState(false);

  const meetingInfo = useSelector((state: any) => state.meetingInfo);
  const { otherParticipant, role, handleCallMentor } = useCallButton({
    buttonClassName: "preplaced-call-button-bottom",
  });

  const allPlugins = useDyteSelector((m) => m.plugins.all);
  const [isBlurred, setIsBlurred] = useState<boolean>(false);
  const [blurMiddleware, setBlurMiddleware] = useState<VideoMiddleware | null>(
    null
  );

  // console.log("dummy push");

  const codeRoughWork: any = allPlugins?.get(
    // "54c140a4-e4ff-402b-85d1-ce23182ad3e3"
    "40b0d43f-388b-4232-b918-c2d900a7d498"
  );

  const whiteBoard: any = allPlugins?.get(
    // "49f9e74a-bf03-4aac-a9c2-3db9f8fdfa41"
    "ae79b269-24ca-4f8a-8112-f96084c8c19a"
  );

  const trialDoc: any = allPlugins?.get("de42dca2-4204-4911-a041-e4822b1b10e7");

  var deviceInfo = {
    ...meeting?.self.device,
  };

  for (let property in deviceInfo) {
    var new_key = property
      .replace(/\.?([A-Z]+)/g, function (x, y) {
        return "_" + y.toLowerCase();
      })
      .replace(/^_/, "");
    deviceInfo[new_key] = deviceInfo[property];
    delete deviceInfo[property];
  }

  useEffect(() => {
    const initBackgroundTransformer = async () => {
      try {
        const transformer = await DyteVideoBackgroundTransformer.init();
        const blurMiddleware =
          await transformer.createBackgroundBlurVideoMiddleware(10);
        setBlurMiddleware(() => blurMiddleware);
      } catch (error) {
        console.error("Error initializing video middleware:", error);
      }
    };

    initBackgroundTransformer();
  }, []);

  const toggleBackgroundBlur = async () => {
    if (!blurMiddleware) {
      console.log("Blur middleware not initialized.");
      return;
    }

    try {
      if (!isBlurred) {
        meeting.self.addVideoMiddleware(blurMiddleware);
        setIsBlurred(true);
      } else {
        meeting.self.removeVideoMiddleware(blurMiddleware);
        setIsBlurred(false);
      }
    } catch (error) {
      console.error("Error toggling blur effect:", error);
    }
  };

  useEffect(() => {
    if (meetingInfo.sessionType === "Mock Interview") {
      codeRoughWork?.activate();
    } else if (
      meetingInfo.sessionType === "1:1 Trial Session" ||
      meetingInfo.sessionType === "1:1 Planning Session"
    ) {
      //  trialDoc.activate();
      if (window.innerWidth >= 768) trialDoc.activate();
      else trialDoc.deactivate();
    }

    // codeRoughWork?.on("closed", () => {
    //   /* -------------------------------------------------------------------------- */
    //   /*         Analytics: CodeRoughWork Close using Top Right Close Button        */
    //   /* -------------------------------------------------------------------------- */
    //   const reqBodyCodeRoughWork = {
    //     user_id:meetingInfo.role === "Mentor" ? meetingInfo.mentorEmail : meetingInfo.menteeEmail,
    //     event: "MyInterviewDoc Closed",
    //     properties: {
    //       ...commonProperties(meetingInfo),
    //       ...deviceInfo,
    //       closed_by: meetingInfo.role,
    //     },
    //   };
    //   trackAnalytics(reqBodyCodeRoughWork);
    // });

    // whiteBoard?.on("closed", () => {
    //   /* -------------------------------------------------------------------------- */
    //   /*          Analytics: Whiteboard Close using top right close button          */
    //   /* -------------------------------------------------------------------------- */
    //   const reqBodyWhiteBoard = {
    //     user_id:meetingInfo.role === "Mentor" ? meetingInfo.mentorEmail : meetingInfo.menteeEmail,
    //     event: "Whiteboard Closed",
    //     properties: {
    //       ...commonProperties(meetingInfo),
    //       ...deviceInfo,
    //       closed_by: meetingInfo.role,
    //     },
    //   };
    //   trackAnalytics(reqBodyWhiteBoard);
    // });

    // trialDoc.on("closed", () => {
    //   /* -------------------------------------------------------------------------- */
    //   /*           Analytics: trialDoc Close using top right close button           */
    //   /* -------------------------------------------------------------------------- */
    //   const reqBodytrialDoc = {
    //     user_id:meetingInfo.role === "Mentor" ? meetingInfo.mentorEmail : meetingInfo.menteeEmail,
    //     event: "TrialDoc Closed",
    //     properties: {
    //       ...commonProperties(meetingInfo),
    //       ...deviceInfo,
    //       closed_by: meetingInfo.role,
    //     },
    //   };
    //   trackAnalytics(reqBodytrialDoc);
    // });
  }, []);

  const tooglePlugin = useCallback(
    (plugin: any) => {
      if (plugin?.active) {
        plugin?.deactivate();
        analyticsClosePlugin(plugin);
      } else {
        setisOpen(false);
        plugin === whiteBoard
          ? codeRoughWork?.deactivate()
          : whiteBoard?.deactivate();
        console.log("plugin", plugin);
        plugin?.activate();
        analyticsOpenPlugin(plugin);
      }
    },
    [codeRoughWork, whiteBoard]
  );

  const { unreadMessages } = useUnreadMessages();

  if (!meeting) {
    return null;
  }

  const analyticsClosePlugin = (plugin: any) => {
    switch (plugin) {
      case whiteBoard:
        /* -------------------------------------------------------------------------- */
        /*                         Analytics: Whiteboard Close                        */
        /* -------------------------------------------------------------------------- */
        const reqBodyWhiteBoard = {
          user_id:
            meetingInfo.role === "Mentor"
              ? meetingInfo.mentorEmail
              : meetingInfo.menteeEmail,
          event: "Whiteboard Closed",
          properties: {
            ...commonProperties(meetingInfo),
            ...deviceInfo,
            closed_by: meetingInfo.role,
          },
        };
        trackAnalytics(reqBodyWhiteBoard);
        break;
      case codeRoughWork:
        /* -------------------------------------------------------------------------- */
        /*                        Analytics: CodeRoughWork Close                      */
        /* -------------------------------------------------------------------------- */
        const reqBodyCodeRoughWork = {
          user_id:
            meetingInfo.role === "Mentor"
              ? meetingInfo.mentorEmail
              : meetingInfo.menteeEmail,
          event: "MyInterviewDoc Closed",
          properties: {
            ...commonProperties(meetingInfo),
            ...deviceInfo,
            closed_by: meetingInfo.role,
          },
        };
        trackAnalytics(reqBodyCodeRoughWork);
        break;
      case trialDoc:
        /* -------------------------------------------------------------------------- */
        /*                        Analytics: CodeRoughWork Close                      */
        /* -------------------------------------------------------------------------- */
        const reqBodytrialDoc = {
          user_id:
            meetingInfo.role === "Mentor"
              ? meetingInfo.mentorEmail
              : meetingInfo.menteeEmail,
          event: "TrialDoc Closed",
          properties: {
            ...commonProperties(meetingInfo),
            ...deviceInfo,
            closed_by: meetingInfo.role,
          },
        };
        trackAnalytics(reqBodytrialDoc);
        break;
    }
  };

  const analyticsOpenPlugin = (plugin: any) => {
    switch (plugin) {
      case whiteBoard:
        /* -------------------------------------------------------------------------- */
        /*                         Analytics: WhiteBoard Open                         */
        /* -------------------------------------------------------------------------- */
        const reqBodyWhiteBoard = {
          user_id:
            meetingInfo.role === "Mentor"
              ? meetingInfo.mentorEmail
              : meetingInfo.menteeEmail,
          event: "Whiteboard Opened",
          properties: {
            ...commonProperties(meetingInfo),
            ...meeting.self.device,
            opened_by: meetingInfo.role,
          },
        };
        console.log("whitwboard", reqBodyWhiteBoard);
        trackAnalytics(reqBodyWhiteBoard);
        break;
      case codeRoughWork:
        /* -------------------------------------------------------------------------- */
        /*                       Analytics: MyInterviewDoc Open                       */
        /* -------------------------------------------------------------------------- */
        const reqBodyCodeRoughWork = {
          user_id:
            meetingInfo.role === "Mentor"
              ? meetingInfo.mentorEmail
              : meetingInfo.menteeEmail,
          event: "MyInterviewDoc Opened",
          properties: {
            ...commonProperties(meetingInfo),
            ...meeting.self.device,
            opened_by: meetingInfo.role,
          },
        };
        trackAnalytics(reqBodyCodeRoughWork);
        break;
      case trialDoc:
        /* -------------------------------------------------------------------------- */
        /*                       Analytics: MyInterviewDoc Open                       */
        /* -------------------------------------------------------------------------- */
        const reqBodytrialDoc = {
          user_id:
            meetingInfo.role === "Mentor"
              ? meetingInfo.mentorEmail
              : meetingInfo.menteeEmail,
          event: "TrialDoc Opened",
          properties: {
            ...commonProperties(meetingInfo),
            ...meeting.self.device,
            opened_by: meetingInfo.role,
          },
        };
        trackAnalytics(reqBodytrialDoc);
        break;
    }
  };

  const openPlannerInNewTab = () => {
    window.open(meetingInfo.plannerLink, "_preplaced");
    toggleisOpen();
    /* -------------------------------------------------------------------------- */
    /*                          Analytics: Planner Opened                         */
    /* -------------------------------------------------------------------------- */
    const reqBody = {
      user_id:
        meetingInfo.role === "Mentor"
          ? meetingInfo.mentorEmail
          : meetingInfo.menteeEmail,
      event: "Planner Doc Opened",
      properties: {
        ...commonProperties(meetingInfo),
        ...deviceInfo,
        opened_by: meetingInfo.role,
        test: true,
      },
    };
    trackAnalytics(reqBody);
  };

  const openAiLearningInNewTab = () => {
    window.open(
      `${process.env.REACT_APP_MENTOR_DASHBOARD_URL}ai_learning?mentee=${meetingInfo?.menteeId}`
    );
  };

  const toggleisOpen = () => {
    setisOpen(!isOpen);
  };

  const toggleScreenShare = async () => {
    if (meeting?.self.screenShareEnabled) {
      await meeting?.self.disableScreenShare();
    } else {
      await meeting.self.enableScreenShare();
    }
    toggleisOpen();
  };

  const toggleDyteChatWithModal = () => {
    toggleDyteChat();
    toggleisOpen();
  };

  return (
    <Flex flexDirection="row" alignItems="ceter" justifyContent="center">
      <Hide below="lg">
        <Flex flexDirection="row" position="absolute" left="0">
          <DyteScreenShareToggle meeting={meeting} />
          {codeRoughWork && (
            <DyteControlbarButton
              className="plugin-btns"
              label="Code-Rough Work"
              icon={!codeRoughWork?.active ? codeRoughWorkIcon : closeIcon}
              onClick={() => tooglePlugin(codeRoughWork)}
            />
          )}
          {meetingInfo.role === "Mentor" &&
            isAiLearningAllowed(meetingInfo.mentorEmail) &&
            meetingInfo.sessionType === "1:1 Sync Up Session" && (
              <DyteControlbarButton
                className="plugin-btns"
                label="Ai Learning"
                icon={aiLearningIcon}
                onClick={openAiLearningInNewTab}
              />
            )}
          {whiteBoard && (
            <DyteControlbarButton
              className="plugin-btns"
              label="Whiteboard"
              icon={!whiteBoard.active ? whiteBoardIcon : closeIcon}
              onClick={() => tooglePlugin(whiteBoard)}
            />
          )}
          {meetingInfo.sessionType === "1:1 Trial Session" ||
          meetingInfo.sessionType === "1:1 Planning Session" ? (
            <DyteControlbarButton
              className="plugin-btns"
              label="Planner"
              icon={plannerIcon}
              onClick={() => tooglePlugin(trialDoc)}
            />
          ) : (
            meetingInfo?.plannerLink && (
              <DyteControlbarButton
                className="plugin-btns"
                label="Planner"
                icon={plannerIcon}
                onClick={openPlannerInNewTab}
              />
            )
          )}
          {meetingInfo.role === "Preplaced" && (
            <DyteRecordingToggle meeting={meeting} />
          )}
        </Flex>
      </Hide>

      <Flex>
        <DyteCameraToggle meeting={meeting} />
        <DyteMicToggle meeting={meeting} />
        <button
          onClick={toggleBackgroundBlur}
          className={`flex flex-col items-center justify-center gap-[5px] w-[80px] hover:bg-[#1e1e1e] p-1 rounded-lg ${
            isBlurred ? "text-white" : "text-red-500"
          } `}
        >
          <div className="w-7 h-7 relative">
            {!isBlurred && (
              <div className="border-t border-red-500 rotate-45 absolute top-1/2 left-0 w-full h-[1px]" />
            )}
            <BlurIcon />
          </div>
          <span className="text-xs text-white">
            {isBlurred ? "Blur On" : "Blur Off"}
          </span>
        </button>
        <DyteSettingsToggle />
        <LeaveButton meeting={meeting} />
        <Show below="lg">
          <Box position="relative">
            <Box
              zIndex="999"
              display={isOpen ? "block" : "none"}
              position="absolute"
              bottom="60px"
              right="0px"
              marginRight="60px"
              backgroundColor="white"
              borderRadius="5px"
              color="black"
            >
              <Button
                width="100%"
                borderRadius="0px"
                fontSize="13px"
                fontWeight="500"
                justifyContent="flex-start"
                backgroundColor="transparent"
                outline="none"
                onClick={toggleDyteChatWithModal}
                display="flex"
                style={{ justifyContent: "space-between" }}
              >
                Chat{" "}
                <div className="bg-black text-white rounded-full w-5 text-xs h-5 flex items-center justify-center">
                  <span>{unreadMessages}</span>
                </div>
              </Button>
              <Button
                width="100%"
                borderRadius="0px"
                fontSize="13px"
                fontWeight="500"
                justifyContent="flex-start"
                backgroundColor="transparent"
                outline="none"
                onClick={handleCallMentor}
                display="flex"
                style={{ justifyContent: "space-between" }}
              >
                Call {role === "Mentor" ? "Mentee" : "Mentor"}
                <PreplacedCallButton
                  theme="light"
                  otherParticipant={otherParticipant}
                  onlyIcon
                  className={`preplaced-call-button-bottom w-full cursor-pointer `}
                  leftSection={<></>}
                  productName="Preplaced Meeting"
                />
              </Button>

              <Button
                width="100%"
                outline="none"
                backgroundColor="transparent"
                borderRadius="0px"
                fontSize="13px"
                fontWeight="500"
                justifyContent="flex-start"
                onClick={toggleScreenShare}
              >
                Share Screen
              </Button>
              <Button
                width="100%"
                outline="none"
                backgroundColor="transparent"
                borderRadius="0px"
                fontSize="13px"
                fontWeight="500"
                justifyContent="flex-start"
                onClick={() => tooglePlugin(codeRoughWork)}
              >
                Code-Rough Work
              </Button>
              {meetingInfo.role === "Mentor" &&
                isAiLearningAllowed(meetingInfo.mentorEmail) &&
                meetingInfo.sessionType === "1:1 Sync Up Session" && (
                  <Button
                    width="100%"
                    outline="none"
                    backgroundColor="transparent"
                    borderRadius="0px"
                    fontSize="13px"
                    fontWeight="500"
                    justifyContent="flex-start"
                    onClick={openAiLearningInNewTab}
                  >
                    Ai Learning
                  </Button>
                )}
              <Button
                width="100%"
                outline="none"
                backgroundColor="transparent"
                borderRadius="0px"
                fontSize="13px"
                fontWeight="500"
                justifyContent="flex-start"
                onClick={() => tooglePlugin(whiteBoard)}
              >
                Whiteboard
              </Button>

              {meetingInfo.sessionType === "Trial Session" ? (
                <Button
                  width="100%"
                  outline="none"
                  backgroundColor="transparent"
                  borderRadius="0px"
                  fontSize="13px"
                  fontWeight="500"
                  justifyContent="flex-start"
                  onClick={() => tooglePlugin(trialDoc)}
                >
                  Planner
                </Button>
              ) : (
                <Button
                  width="100%"
                  outline="none"
                  backgroundColor="transparent"
                  borderRadius="0px"
                  fontSize="13px"
                  fontWeight="500"
                  justifyContent="flex-start"
                  onClick={openPlannerInNewTab}
                >
                  Planner
                </Button>
              )}
            </Box>

            <div className="relative">
              <span
                style={{ zIndex: 1 }}
                className="absolute -top-1 right-[10px] bg-black leading-[1] rounded-full"
              >
                {unreadMessages}
              </span>

              <DyteControlbarButton
                label="More"
                icon={isOpen ? arrowDownIcon : arrowUpIcon}
                onClick={toggleisOpen}
              />
            </div>
          </Box>
        </Show>
      </Flex>

      <Hide below="lg">
        <Box position="absolute" right="0">
          <Flex>
            <ChatButton
              showChatContainer={showChatContainer}
              toggleDyteChatWithModal={toggleDyteChatWithModal}
            />
            <CallButton />
          </Flex>
        </Box>
      </Hide>
    </Flex>
  );
};

export default Footer;
