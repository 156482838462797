import { logger } from "../logtail";

export const getMeetingInfoMapper = (data: any, isV1Meet: boolean) => {
  try {
    const mappedDataMeetingInfo = {
      menteeName: data.candidate[0].name,
      menteeEmail: data.candidate[0].email,
      menteeId: data.candidate[0].id,
      menteePhotoUrl: data.candidate[0].photoUrl,
      menteePhoneNumber: data.packages[0].menteePhoneNumber,
      mentorName: data.mentor[0].name,
      mentorEmail: data.mentor[0].email,
      mentorId: data.mentor[0].id,
      mentorPhotoUrl: data.mentor[0].photoUrl,
      mentorPhoneNumber: data.packages[0].mentorPhoneNumber,
      plannerLink: data.packages[0].plannerDocLink,
      feedbackToBeFilledByCandidate: data.feedbackToBeFilledByCandidate,
      feedbackToBeFilledByMentor: data.feedbackToBeFilledByMentor,
      isFeedbackSubmittedByMentee: data.isFeedbackSubmittedByMentee,
      isFeedbackSubmittedByMentor: data.isFeedbackSubmittedByMentor,
      myInterviewDocLink: data.myInterviewDocLink,
      role: "",
      authToken: "",
      sessionType: data.sessionConfiguration[0].sessionType,
      sessionId: data.id,
      sessionTitle: data.sessionTitle,
      packageType: data.packages[0].packageVersionFromPackageConfiguration[0],
      packageName: data.packages[0].packageFromPackageConfiguration[0],
      relationshipManagerEmail: data.relationshipManagers[0].email,
      relationshipManagerId: data.relationshipManagers[0].id,
      relationshipManagerName: data.relationshipManagers[0].name,
      relationshipManagerPhoneNumber: data.relationshipManagers[0].phoneNumber,
      packageId: data.packages[0].id,
      plan: data?.packages?.[0]?.plan,
      sessionCount: data?.packages?.[0]?.sessionCount,
      isRecurring: data.isRecurring,
      recordingStatus: data.recordingStatus,
      isV1Meet: isV1Meet,
    };
    return mappedDataMeetingInfo;
  } catch (e) {
    console.error("error while mapping the meeting data: ", e);
    logger.error('error while mapping the meeting data', e)
  }
};
