import React from "react";
import PreplacedLogo from "../../assets/preplaced-logo.svg";
import Illustration from "../../assets/landing-illustration.svg";
import { Box, Container, Flex, Heading, Text, Stack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export const MainScreenComponent: React.FC<{}> = () => {
  return (
    <>
      <Box w="100%" p="5">
        <img className="logo" src={PreplacedLogo} alt="Preplaced" />
      </Box>
      <Container h="calc(100vh - 73px)" maxW="100vw" color="white">
        <Flex
          alignItems="center"
          h="inherit"
          gap="20px"
          flexDirection={{ base: "column", md: "column", lg: "row" }}
          justifyContent="center"
        >
          <Box maxW="600px" p="20px">
            <Stack spacing={3}>
              <Heading>
                To join the meeting click on the link in the calendar invite.
              </Heading>
              <Text>
                If not scheduled, visit Preplaced to schedule your call with the
                mentor
              </Text>
              <Link
                className="cta-button"
                to={{pathname:"https://app.preplaced.in?utm_source=preplaced-meetings"}}
                target="_blank"
              >
                Schedule your session with the Mentor →
              </Link>
            </Stack>
          </Box>
          <Box>
            <img
              className="illustration-img"
              src={Illustration}
              alt="Preplaced"
            />
          </Box>
        </Flex>
      </Container>
    </>
  );
};
