import { useSelector } from "react-redux";
import stateUtilityInstance from "../../singleton/global-state";
import useCallButton from "../call-button/use-call-button";

const useInstantCallPopUp = () => {
  const { meetingInfo, otherParticipant, role, handleCallMentor } =
    useCallButton({});

  return { meetingInfo, otherParticipant, role, handleCallMentor };
};

export default useInstantCallPopUp;
