import { DyteLeaveButton } from "@dytesdk/react-ui-kit";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FeedbackTrial from "../FeedbackTrial/feedback-trial";
import { getCookie } from "../../utils/getCookie";

const LeaveButton = ({ meeting }: any) => {
  const meetingInfo = useSelector((state: any) => state.meetingInfo);
  const { sessionType, role } = meetingInfo;
  const [showButtonFor, setShowButtonFor] = useState("Long");

  useEffect(() => {
    if (sessionType.includes("Trial") && role=== "Mentee" && !getCookie(meetingInfo?.sessionId)) {
      setShowButtonFor("Trial");
    }
  }, [sessionType]);

  const leaveSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-[28px] w-[28px]"
      viewBox="0 0 24 24"
    >
      <path
        d="m21.903 13.202-.191.816a2.75 2.75 0 0 1-2.955 2.107l-1.755-.178A2.75 2.75 0 0 1 14.6 13.83l-.389-1.686a.25.25 0 0 0-.113-.157c-.321-.197-1.034-.32-2.098-.32-.786 0-1.386.067-1.786.189-.14.043-.238.085-.301.116l-.09.048-.015.04-.409 1.77A2.75 2.75 0 0 1 7 15.948l-1.746.177A2.75 2.75 0 0 1 2.3 14.03l-.196-.817a3.75 3.75 0 0 1 1.13-3.651C5.134 7.839 8.064 7 12 7c3.943 0 6.875.842 8.776 2.57a3.75 3.75 0 0 1 1.172 3.41l-.045.222Zm-1.426-.514a2.25 2.25 0 0 0-.71-2.009C18.184 9.241 15.602 8.5 12 8.5c-3.598 0-6.177.739-7.76 2.172a2.25 2.25 0 0 0-.677 2.19l.196.818a1.25 1.25 0 0 0 1.342.953l1.746-.178a1.25 1.25 0 0 0 1.09-.962l.423-1.82.043-.136c.376-.998 1.591-1.37 3.597-1.37 1.318 0 2.265.164 2.88.54.401.245.687.642.793 1.1l.389 1.685c.12.522.56.909 1.092.963l1.754.178a1.25 1.25 0 0 0 1.343-.958l.192-.816.034-.171Z"
        fill="currentColor"
      ></path>
    </svg>
  );

  const [showFeedbackPop, setShowFeedbackPop] = useState(false);

  const handleShowFeedback = () => {
    setShowFeedbackPop(!showFeedbackPop);
  };

  return (
    <>
      {
        {
          Trial: (
            <div
              onClick={handleShowFeedback}
              className="px-1 pt-1 hover:text-[#F34956] flex flex-col items-center justify-between rounded-lg text-white hover:border-[#F34956] border-black cursor-pointer border-2 w-[76px] h-[54px]"
            >
              {leaveSvg}
              <div className="text-xs">Leave</div>
            </div>
          ),
          Long: <DyteLeaveButton />,
        }[showButtonFor]
      }
      {showFeedbackPop ? <FeedbackTrial meeting={meeting} /> : null}
    </>
  );
};

export default LeaveButton;
