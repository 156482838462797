import { Flex, Spinner, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

const TEXT_CHANGE_INTERVAL = 500;
interface LoadingProps {
  loaderTextList:any;
}

const Loading = ({ loaderTextList }: LoadingProps) => {
  const [leaderText, setLoaderText] = useState(loaderTextList[0]);
  let loadertextCounter = 0;

  useEffect(() => {
    (window as any).textChangeAnimation = setInterval(() => {
      if (++loadertextCounter < loaderTextList.length) {
        setLoaderText(loaderTextList[loadertextCounter]);
      } else {
        clearInterval((window as any).textChangeAnimation);
      }
    }, TEXT_CHANGE_INTERVAL);
  }, []);

  return (
    <>
      { (
        <Flex
          color="white"
          flexDirection={"column"}
          h="100vh"
          justifyContent={"center"}
          alignItems="center"
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
          <Text m={"20px 10px"}>{leaderText}</Text>
        </Flex>
      )}
    </>
  );
};

export default Loading;
