import { Flex, Container, Heading, Box, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logger } from "../../logtail";
import {
  setMeetingInfo,
  setAuthToken,
  setRole,
  setMeetingStatus,
} from "../../redux/reducers/meetingReducer/meetingSlice";
import { joinExistingRoom } from "../../utils";
import Navbar from "../../components/Navbar/Navbar";
import Card from "../../components/Card/Card";
import MentorLogo from "../../components/MentorLogo/MentorLogo";
import CandidateLogo from "../../components/CandidateLogo/CandidateLogo";
import { useEffect, useState } from "react";
import { getMeetingData } from "../../utils";
import Loading from "../../components/Loader/Loader";
import { loaderTextListOnboarding } from "./loader-text-list";
import MyChatComponent from "../../components/my-chat-component/my-chat-component";

const Onboarding = () => {
  let params: {
    id: string;
    room: string;
  } = useParams();

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    var meetScreenAndStatus = {
      currentScreen: "Onboarding",
      meetingStatus: "Pending",
    };
    getMeetingData(params.id, params.room).then((meetingData) => {
      dispatch(setMeetingInfo(meetingData));
      dispatch(setMeetingStatus(meetScreenAndStatus));
      setLoader(false);
    });
  }, [getMeetingData]);

  //getting name from redux store
  const meetingInfo = useSelector((state: any) => state.meetingInfo);

  //dispatcher
  const dispatch = useDispatch();

  const commonjoinExistingRoom = (meeitngInfo: any) => {
    clearInterval((window as any).textChangeAnimation);
    // setLoaderText("Getting You Into the meeting...");
    setLoader(true);
    joinExistingRoom(params.id, params.room, meeitngInfo)
      .then((result: any) => {
        dispatch(setAuthToken(result.authToken));
      })
      .catch((error) => {
        logger.error("Error in getting meeting data:", error);
        console.error(error);
      });
  };

  // handleSubmit for Name
  const handleSubmit = (role: string) => {
    let userPostHogData;
    if (role === "Mentee") {
      const candidate = {
        ...meetingInfo,
        role: "Mentee",
      };
      commonjoinExistingRoom(candidate);
      dispatch(setRole(candidate));
      userPostHogData = {
        email: meetingInfo?.menteeEmail,
        recordId: meetingInfo?.menteeId,
        name: meetingInfo?.menteeName,
      };
    }
    if (role === "Mentor") {
      const mentor = {
        ...meetingInfo,
        role: "Mentor",
      };
      commonjoinExistingRoom(mentor);
      dispatch(setRole(mentor));
      userPostHogData = {
        email: meetingInfo?.mentorEmail,
        recordId: meetingInfo?.mentorId,
        name: meetingInfo?.mentorName,
      };
    }
    if (role === "Preplaced") {
      const preplaced = {
        ...meetingInfo,
        role: "Preplaced",
      };
      commonjoinExistingRoom(preplaced);
      dispatch(setRole(preplaced));
    }
    var meetScreenAndStatus = {
      currentScreen: "Initial Meeting",
      meetingStatus: "Joining",
    };
    dispatch(setMeetingStatus(meetScreenAndStatus));
    if (userPostHogData?.email) {
      (window as any).posthog.identify(userPostHogData?.email, userPostHogData);
    }
  };

  return (
    <>
      {!loader ? (
        <Flex h="inherit" flexDirection="column">
          {/* Navbar of Preplaced logo */}
          <Navbar />

          {/* Call to Action Buttons */}
          <Container
            color="white"
            display="flex"
            flexDirection="column"
            h="inherit"
            justifyContent="center"
            maxW="3xl"
          >
            <Heading as="h1" marginBottom="1rem" size="md">
              Are you ready to join?
            </Heading>
            <Flex
              flexDirection={{ md: "column", lg: "row", base: "column" }}
              justifyContent="space-between"
              gap="1rem"
            >
              <Card
                handleSubmit={() => handleSubmit("Mentor")}
                logo={CandidateLogo}
                backgroundColor="blue.50"
                hoverBackGrounfColor="primary.500"
                heading={`Join as ${meetingInfo.mentorName ?? "Mentor"}`}
                color="primary.500"
                role={meetingInfo.mentorName && "mentor"}
              />
              <Card
                handleSubmit={() => handleSubmit("Mentee")}
                logo={MentorLogo}
                backgroundColor="red.50"
                hoverBackGrounfColor="red.500"
                heading={`Join as ${meetingInfo.menteeName ?? "Candidate"}`}
                color="red.500"
                role={meetingInfo.menteeName && "mentee"}
              />
            </Flex>
          </Container>
          <Box textAlign="center" m="20px">
            <Text
              cursor="pointer"
              color="gray.700"
              display="inline"
              fontSize="sm"
              onClick={() => handleSubmit("Preplaced")}
            >
              Join as Preplaced
            </Text>
          </Box>
        </Flex>
      ) : (
        <Loading loaderTextList={loaderTextListOnboarding} />
      )}
    </>
  );
};

export default Onboarding;
