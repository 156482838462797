interface GlobalState {
  [key: string]: unknown;
}

class StateUtility {
  private static instance: StateUtility;
  private globalState: GlobalState;

  private constructor() {
    if (StateUtility.instance) {
      throw new Error("New instance cannot be created!!");
    }

    this.globalState = {};

    StateUtility.instance = this;
  }

  getPropertyByName<T>(propertyName: string): T {
    return this.globalState[propertyName] as T;
  }

  setPropertyValue(propertyName: string, propertyValue: unknown): void {
    this.globalState[propertyName] = propertyValue;
  }

  removeProperty(propertyName: string): void {
    delete this.globalState[propertyName];
  }

  public static getInstance(): StateUtility {
    if (!StateUtility.instance) {
      StateUtility.instance = new StateUtility();
    }

    return StateUtility.instance;
  }
}

const stateUtilityInstance = Object.freeze(StateUtility.getInstance());

export default stateUtilityInstance;
