import React from "react";

interface iProps {
  color: boolean
}

const MentorLogo = ({color}: iProps) => {
return(
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.1861 1.05382C24.4566 0.741176 23.6308 0.741176 22.9013 1.05382L2.60132 9.75382C1.53504 10.2108 0.843689 11.2593 0.843689 12.4193C0.843689 13.5794 1.53504 14.6279 2.60132 15.0849L10.2698 18.3713C10.5486 18.0554 10.8982 17.7981 11.3013 17.6253L22.9013 12.6539C24.3734 12.0229 26.0783 12.7049 26.7092 14.177C27.3401 15.6491 26.6582 17.354 25.1861 17.9849L17.2771 21.3745L22.9013 23.7849C23.6308 24.0975 24.4566 24.0975 25.1861 23.7849L45.4861 15.0849C46.5523 14.6279 47.2437 13.5794 47.2437 12.4193C47.2437 11.2593 46.5523 10.2108 45.4861 9.75382L25.1861 1.05382Z"
        fill={!color ? "#F34956" : "#ffffff"}
      />
      <path
        d="M4.64282 22.2698L9.54369 24.3702V36.265C8.54965 36.0395 7.53414 35.8709 6.50074 35.7625C5.13841 35.6197 4.06181 34.5431 3.91896 33.1807C3.80303 32.075 3.74369 30.9534 3.74369 29.8189C3.74369 27.2189 4.05518 24.6906 4.64282 22.2698Z"
        fill={!color ? "#F34956" : "#ffffff"}
      />
      <path
        d="M22.0136 43.0796C20.0622 41.1667 17.8135 39.5587 15.3437 38.3311V26.8559L20.6166 29.1158C22.8051 30.0537 25.2823 30.0537 27.4708 29.1158L43.4446 22.2698C44.0322 24.6906 44.3437 27.2189 44.3437 29.8189C44.3437 30.9534 44.2843 32.075 44.1684 33.1807C44.0256 34.5431 42.949 35.6197 41.5866 35.7625C35.5754 36.3928 30.1696 39.0644 26.0738 43.0796C24.9461 44.1851 23.1412 44.1851 22.0136 43.0796Z"
        fill={!color ? "#F34956" : "#ffffff"}
      />
      <path
        d="M12.4437 47.2193C14.0453 47.2193 15.3437 45.9209 15.3437 44.3193V38.3311C13.5206 37.425 11.5771 36.7262 9.54369 36.265V44.3193C9.54369 45.9209 10.8421 47.2193 12.4437 47.2193Z"
        fill={!color ? "#F34956" : "#ffffff"}
      />
    </svg>
)
}

export default MentorLogo;