import React from "react";
import { ReactComponent as GenerateAIIcon } from "../../assets/generate-ai.svg";
import useSummaryPopupHook from "./use-summary-popup-hook";
import NewBadgeIcon from "../../assets/new_badge.png";
import {ReactComponent as XIcon} from "../../assets/x.svg";

const SummaryPopup = () => {
  const { showSummaryPopUp, handleTogglePopUp } = useSummaryPopupHook();
  return (
    <div className="relative">
      <div onClick={handleTogglePopUp} className="cursor-pointer">
        <GenerateAIIcon />
      </div>
      {showSummaryPopUp && (
        <div className="absolute top-6 z-10 right-0 w-72 h-56 p-5 bg-white rounded-lg flex-col justify-start items-start gap-3 inline-flex">
          <div className="self-stretch h-20 flex-col justify-start items-start gap-2 flex">
            <div className="self-stretch justify-between items-center inline-flex">
              <div className="rounded-tl-sm rounded-tr-md rounded-bl-md rounded-br-sm justify-center items-center gap-2.5 flex">
                <div className="text-cente w-10 text-white text-xs font-bold">
                  <img src={NewBadgeIcon} alt="New Badge"/>
                </div>
              </div>
              <div
                className="w-5 h-5 relative cursor-pointer"
                onClick={handleTogglePopUp}
              >
                <XIcon/>
              </div>
            </div>
            <div className="self-stretch justify-between items-start inline-flex">
              <div className="grow shrink basis-0 text-sky-700 text-lg font-semibold leading-relaxed">
                Session Summary with AI <br/>is ON!
              </div>
            </div>
          </div>
          <div className="self-stretch">
            <span className="text-gray-500 text-base font-normal leading-normal">
              The session is being transcribed for this feature. Summary will be
              generated around an hour
            </span>
            <span className="text-gray-500 text-base font-bold leading-normal">
              {" "}
              after session ends.
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default SummaryPopup;
