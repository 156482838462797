import stateUtilityInstance from "../../singleton/global-state";
import { PreplacedCallButton } from "preplaced-call-service";
// import { ReactComponent as CallIcon } from "../../assets/call-icon-white.svg";
import { callIcon } from "../icons/icons";
import { useSelector } from "react-redux";
import { DyteControlbarButton } from "@dytesdk/react-ui-kit";
import useCallButton from "./use-call-button";

const CallButton = () => {
  const { meetingInfo, otherParticipant, role, handleCallMentor } =
    useCallButton({});

  return (
    <div className=" ">
      <div className="h-0 w-0">
        {" "}
        <PreplacedCallButton
          theme="light"
          otherParticipant={otherParticipant}
          onlyIcon
          className={`preplaced-call-button w-full cursor-pointer `}
          leftSection={<></>}
          productName="Preplaced Meeting"
        />
      </div>

      {/* <p className="text-white text-xs">Call</p> */}
      <DyteControlbarButton
        label="Call"
        icon={callIcon}
        onClick={handleCallMentor}
      />
    </div>
  );
};

export default CallButton;
