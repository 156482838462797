import React from "react";

interface iProps {
  color: boolean
}

const CandidateLogo = ({color}: iProps) => (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.4438 25.0196C49.4438 38.4954 38.5196 49.4196 25.0438 49.4196C11.5681 49.4196 0.643799 38.4954 0.643799 25.0196C0.643799 11.5439 11.5681 0.619629 25.0438 0.619629C38.5196 0.619629 49.4438 11.5439 49.4438 25.0196ZM31.1438 15.8696C31.1438 19.2386 28.4127 21.9696 25.0438 21.9696C21.6749 21.9696 18.9438 19.2386 18.9438 15.8696C18.9438 12.5007 21.6749 9.76963 25.0438 9.76963C28.4127 9.76963 31.1438 12.5007 31.1438 15.8696ZM25.0436 28.0696C18.8901 28.0696 13.5878 31.7142 11.1777 36.9626C14.5336 40.8553 19.5009 43.3196 25.0437 43.3196C30.5865 43.3196 35.5537 40.8554 38.9096 36.9628C36.4995 31.7144 31.1972 28.0696 25.0436 28.0696Z"
        fill={!color ? "#0C5BF3" : "#ffffff"}
      />
    </svg>
);

export default CandidateLogo;