export const commonProperties = (meetingInfo: any) => {
  return {
    session_id: meetingInfo?.sessionId,
    mentor_name: meetingInfo?.mentorName,
    mentee_name: meetingInfo?.menteeName,
    session_type: meetingInfo?.sessionType,
    session_name: meetingInfo?.sessionTitle,
    mentor_id: meetingInfo?.mentorEmail,
    mentor_record_id: meetingInfo?.mentorId,
    mentee_id: meetingInfo?.menteeEmail,
    mentee_record_id: meetingInfo?.menteeId,
    package_type: meetingInfo?.packageType,
    package_name: meetingInfo?.packageName,
    product_name: "Preplaced Meetings",
    package_id: meetingInfo?.packageId,
    test: document?.location?.hostname === "meet.preplaced.in" ? false : true,
    rm_name: meetingInfo?.relationshipManagerName,
  };
};
