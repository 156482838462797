import React, { useEffect } from "react";
import { ReactComponent as NewBadgeIcon } from "../../assets/instant-call-new-badge.svg";
import { ReactComponent as XIcon } from "../../assets/x.svg";
import { ReactComponent as CallIcon } from "../../assets/call-icon-white.svg";
import { PreplacedCallButton } from "preplaced-call-service";
import useInstantCallPopUp from "./use-instant-call-popup";
import { trackAnalytics } from "../../analytics/analyticsApi";
import { useSelector } from "react-redux";

const InstantCallPopup = ({
  showInstantCallPopUp,
  hideInstantCallPrompt,
  sessionDetails,
}: any) => {
  const { role, otherParticipant, handleCallMentor } = useInstantCallPopUp();

  const meetingInfo = useSelector((state: any) => state.meetingInfo);

  useEffect(() => {
    const requestBody = {
      user_id:
        meetingInfo.role === "Mentor"
          ? meetingInfo.mentorEmail
          : meetingInfo.menteeEmail,
      event: "Meeting Unattendance",
      properties: {
        mentee_email: meetingInfo?.menteeEmail,
        mentor_email: meetingInfo?.mentorEmail,
        package_name: meetingInfo?.packageName,
        rm_name: meetingInfo?.relationshipManagerName,
        mentor_name: meetingInfo?.mentorName,
        mentee_name: meetingInfo?.menteeName,
        meetingLink: sessionDetails?.meetingLinkFromAPI[0],
        mentor_phone: sessionDetails?.phoneNumberFromMentors[0],
        mentee_phone: sessionDetails?.phoneNumberFromCandidates[0],
        inside_meeting: meetingInfo?.role,
        session_datetime: sessionDetails?.sessionDateTime,
      },
    };
    //console.log(requestBody)
    trackAnalytics(requestBody);
  }, [meetingInfo, sessionDetails]);

  return (
    <div className="relative">
      <div className="z-10  p-4 bg-white rounded-lg flex-col justify-start items-start gap-3 inline-flex">
        <div className="flex w-full justify-between items-start">
          <NewBadgeIcon />
          <button onClick={hideInstantCallPrompt}>
            <XIcon />
          </button>
        </div>
        <div className="space-y-2">
          <p className="text-[#000] text-sm font-medium leading-5">
            Give an Instant call to your{" "}
            {role === "Mentor" ? "mentee" : "mentor"}
          </p>
          <p className="text-[#000] text-xs">
            Looks like your {role === "Mentor" ? "mentee" : "mentor"} hasn't
            joined the{" "}
            {meetingInfo.sessionType === "1:1 Sync Up Session"
              ? "sync up"
              : "trial"}{" "}
            session yet, remind them by using instant call
          </p>
        </div>
        <button
          className="w-full py-2 px-[14px] bg-[#0E121B]  flex justify-center items-center gap-2"
          onClick={handleCallMentor}
        >
          <p className="text-[#FFF] text-sm font-medium">
            Call {role === "Mentor" ? "mentee" : "mentor"}
          </p>
          <CallIcon />
          <PreplacedCallButton
            theme="light"
            otherParticipant={otherParticipant}
            onlyIcon
            className={`preplaced-call-button w-full cursor-pointer !rounded-none !border-none !bg-white !p-0 !shadow-none`}
            productName="Preplaced Meeting"
          />
        </button>
      </div>
    </div>
  );
};

export default InstantCallPopup;
