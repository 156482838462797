import React, { useState } from "react";
import BuildOpportunityReminder from "../BuildOpportunityReminder/BuildOpportunityReminder";
import stateUtilityInstance from "../../singleton/global-state";
import ScheduleSessionPrompt from "../schedule-session-prompt/schedule-session-prompt";
import bellWarn from "../../assets/bellWarn.svg";
import minimizeIcon from "../../assets/minimize.svg";

const SessionEndPopUps = ({
  meetingInfo,
  hideScheduleSessionPopUp,
  mentorTaggingCard,
  scheduleSessionPopUp,
  setMentorTaggingCard,
  setBeforeSessionEndsPops,
  beforeSessionEndsPops,
}: any) => {
  const candidateData: any =
    stateUtilityInstance.getPropertyByName("candidateData");

  const [isMinimized, setIsMinimized] = useState(false);

  const handleCloseBeforeModal = () => {
    if (mentorTaggingCard === true && scheduleSessionPopUp === false) {
      return setBeforeSessionEndsPops(false);
    }

    if (mentorTaggingCard === false && scheduleSessionPopUp === true) {
      return setBeforeSessionEndsPops(false);
    }
  };

  const handleClickSessionPopUp = () => {
    hideScheduleSessionPopUp();
    handleCloseBeforeModal();
  };

  return (
    <div
      className={`rounded-t-[10px] p-6 border border-[#e4e7ec] bg-white absolute bottom-0 right-0 max-w-[520px] mr-10`}
    >
      {isMinimized ? (
        <div className="flex gap-5 items-center justify-center">
          <div className="flex items-center justify-center gap-2 mr-4">
            <div
              className={`w-8 h-8 p-1 bg-amber-100 bg-opacity-50 rounded-full justify-center items-center inline-flex`}
            >
              <div className="grow shrink basis-0 self-stretch p-1 bg-amber-100 rounded-full justify-center items-center inline-flex">
                <div className="w-4 h-4">
                  <img
                    src={bellWarn}
                    alt=""
                    className="w-4 h-4"
                  />
                </div>
              </div>
            </div>
            <h1
              className={`text-[#344054] font-semibold leading-[normal] text-lg w-[250px] text-xl`}
            >
              Before your session ends
            </h1>
          </div>
          <img
            src={minimizeIcon}
            alt="minimize_button"
            className="cursor-pointer absolute right-7 top-[30px]"
            onClick={() => setIsMinimized(false)}
          />
        </div>
      ) : (
        <>
          <div className="flex gap-3">
            <h1
              className={`text-[#344054] font-semibold leading-[normal] text-lg w-[250px] text-xl`}
            >
              Before your session ends...
            </h1>
            <img
              src={minimizeIcon}
              alt="minimize_button"
              className="cursor-pointer absolute right-7 top-[30px]"
              onClick={() => setIsMinimized(true)}
            />
          </div>

          <div>
            {mentorTaggingCard && (
              <div>
                {candidateData.hasOwnProperty("profileBuildingStatus") ===
                  false || candidateData.profileBuildingStatus === "Applied" ? (
                  <BuildOpportunityReminder
                    cardType={1}
                    setShowBuildOpportunityReminder={setMentorTaggingCard}
                    handleCloseBeforeModal={handleCloseBeforeModal}
                  />
                ) : null}
                {candidateData.profileBuildingStatus === "Approved" ? (
                  <BuildOpportunityReminder
                    cardType={2}
                    setShowBuildOpportunityReminder={setMentorTaggingCard}
                    handleCloseBeforeModal={handleCloseBeforeModal}
                  />
                ) : null}
              </div>
            )}

            {scheduleSessionPopUp && (
              <ScheduleSessionPrompt
                hideScheduleSessionPopUp={handleClickSessionPopUp}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SessionEndPopUps;
