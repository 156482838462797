import axios from "axios";
import { getCall } from "./apiServices/apiServices";
import { getMeetingInfoMapper } from "./mapper/getMeetingInfoMapper";
import * as Sentry from "@sentry/react";
import stateUtilityInstance from "./singleton/global-state";
import moment from "moment";
import { logger } from "./logtail";

const {
  REACT_APP_MEETING_INFO_BASE_URL: MEETING_INFO_BASE_URL,
  REACT_APP_GET_USER_DATA_FROM_NEW_OPERATIONS:
  GET_USER_DATA_FROM_NEW_OPERATIONS,
  REACT_APP_BASE_URL_DASHBOARD_API,
} = process.env;

export const joinExistingRoom = async (
  meetingId: string,
  roomName: string,
  meetingInfo?: any
) => {
  try {
    const clientID = `${meetingInfo.role}_${Math.random()
      .toString(36)
      .substring(7)}`;
    let authResponse: any = {};
    if (meetingInfo.isV1Meet) {
      let reqBody: any = {
        clientSpecificId: clientID,
        userDetails: {
          name:
            (meetingInfo.role === "Mentor" && meetingInfo.mentorName) ||
            (meetingInfo.role === "Mentee" && meetingInfo.menteeName) ||
            (meetingInfo.role === "Preplaced" && `Preplaced`) ||
            clientID,
        },
      };
      if (meetingInfo.role === "Preplaced") {
        reqBody["presetName"] = "preplaced_operations";
      }

      const resp = await axios({
        url: `${MEETING_INFO_BASE_URL}dyte/add-participant?meeting_id=${meetingId}`,
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        data: reqBody,
      });

      authResponse = resp.data.authResponse;
    } else {
      let newReqBodyV2: any = {
        name:
          (meetingInfo.role === "Mentor" && meetingInfo.mentorName) ||
          (meetingInfo.role === "Mentee" && meetingInfo.menteeName) ||
          (meetingInfo.role === "Preplaced" && `Preplaced`) ||
          clientID,
        custom_participant_id: clientID,
        preset_name:
          (meetingInfo.role === "Mentor" && "mentor") ||
          (meetingInfo.role === "Mentee" && "mentee") ||
          (meetingInfo.role === "Preplaced" && `preplaced_operations`) ||
          "",
      };

      const resp = await axios({
        url: `${MEETING_INFO_BASE_URL}dyte/add-participant/v2?meeting_id=${meetingId}`,
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        data: newReqBodyV2,
      });

      authResponse = {
        authToken: resp.data.data.token,
        id: resp.data.data.id,
      };
    }

    return authResponse;
  } catch (error) {
    logger.error("Error in joinExistingRoom", error);
    console.error(error);
  }
};

export const getMeetingData = async (meetingId: string, roomName: string) => {
  try {
    const respSession: any = await getCall({
      url: `${REACT_APP_BASE_URL_DASHBOARD_API}sessions/public/meeting/${meetingId}`,
    });
    let isV1Meet = true;
    if (
      respSession.meetingCreatedTime &&
      moment(respSession?.meetingCreatedTime).isAfter(
        moment("2024-04-03T11:00:00+05:30") // TODO:Praty -> v2 Release Timestamp hardcoded - temp
      )
    ) {
      isV1Meet = false;
    }
    // console.log("isV1Meet", respSession?.meetingCreatedTime, isV1Meet);
    const candidateRecordID = respSession.candidate[0];
    const mentorRecordID = respSession.mentor[0];
    const packageRecordID = respSession.packages[0];
    const relationshipManagerRecordID = respSession.relationshipManagers[0];
    const sessionConfigRecordID = respSession.sessionConfiguration[0];
    // const isRecurring = respSession?.nextRecurringSessionDatetime ? true : false
    let isRecurring;
    const ifCondition =
      candidateRecordID &&
      mentorRecordID &&
      packageRecordID &&
      relationshipManagerRecordID &&
      sessionConfigRecordID;
    if (ifCondition) {
      // API Calling to get Candidate Data
      const respCandidate = await getCall({
        url: `${REACT_APP_BASE_URL_DASHBOARD_API}candidates/public?ids=${candidateRecordID}`,
      });

      const candidateData = {
        name: respCandidate[0].name,
        email: respCandidate[0].email,
        id: respCandidate[0].recordId,
        photoUrl: respCandidate[0].photoUrl,
      };

      // API Calling to get Mentor Data
      const respMentor = await getCall({
        url: `${REACT_APP_BASE_URL_DASHBOARD_API}mentors/public/get-profile-by-id?id=${mentorRecordID}`,
      });

      const mentorData = {
        name: respMentor.name,
        email: respMentor.email,
        id: respMentor.recordId,
        photoUrl: respMentor.photoUrl,
      };

      // API Calling to get Packages Data
      const respPackages = await getCall({
        url: `${REACT_APP_BASE_URL_DASHBOARD_API}packages/public/get-details-by-id?package_id=${packageRecordID}`,
      });
      const packagesData = {
        plannerDocLink: respPackages.plannerDocLink
          ? respPackages.plannerDocLink
          : "",
        packageVersionFromPackageConfiguration:
          respPackages["packageVersion(FromPackageConfiguration)"],
        packageFromPackageConfiguration:
          respPackages["package(FromPackageConfiguration)"],
        id: respPackages.recordId,
        plan: respPackages?.plan,
        sessionCount: respPackages?.sessionCount,

        menteePhoneNumber: respPackages?.['phoneNumber(FromCandidates)']?.[0],
        mentorPhoneNumber: respPackages?.['phoneNumber(FromMentors)']?.[0]
      };

      isRecurring =
        respPackages?.hasOwnProperty("recurringSessions") &&
        respPackages?.recurringSessions !== "" &&
        respPackages?.recurringSessionsSchedule !== "" &&
        respPackages?.hasOwnProperty("recurringSessionsSchedule");

      // API Calling to get RelationShip Manager Data
      const respRelationShipManager = await getCall({
        url: `${REACT_APP_BASE_URL_DASHBOARD_API}relationship-managers/public/get-details-by-id?relationshipmanager_id=${relationshipManagerRecordID}`,
      });
      const relationshipManagerData = {
        name: respRelationShipManager.name,
        email: respRelationShipManager.email,
        id: respRelationShipManager.recordId,
        phoneNumber: respRelationShipManager.phoneNumber,
      };

      // API Calling to get Session Configuration
      const respSessionConfig = await getCall({
        url: `${REACT_APP_BASE_URL_DASHBOARD_API}cfg-session/public/get-details-by-id?ids=${sessionConfigRecordID}`,
      });
      const sessionConfigData = {
        sessionType: respSessionConfig.sessionType,
      };

      stateUtilityInstance.setPropertyValue("mentorData", respMentor);
      stateUtilityInstance.setPropertyValue(
        "candidateData",
        respCandidate?.[0]
      );


      // Other participant data for instant calling
      let otherParticipants = [];

      // const selfDetails = {
      //   name: allMenteeData.name,
      //   email: allMenteeData.email,
      //   ...(allMenteeData.photoUrl && { picture: allMenteeData.photoUrl }),
      //   role: 'Mentee',
      //   recordId: allMenteeData.recordId,
      //   phoneNumber: allMenteeData.phoneNumber,
      //   isPhoneVerified: allMenteeData?.instantCallVerification,
      // };

      const meetingData = {
        mentor: [mentorData],
        candidate: [candidateData],
        packages: [packagesData],
        sessionConfiguration: [sessionConfigData],
        relationshipManagers: [relationshipManagerData],
        id: respSession.recordId,
        sessionTitle: respSession.sessionTitle,
        myInterviewDocLink: respSession.myinterviewdoclink,
        feedbackToBeFilledByCandidate: respSession.feedbackToBeFilledByCandidate
          ? respSession.feedbackToBeFilledByCandidate
          : "",
        feedbackToBeFilledByMentor: respSession.feedbackToBeFilledByMentor
          ? respSession.feedbackToBeFilledByMentor
          : "",
        isFeedbackSubmittedByMentee:
          respSession.frFeedbackSubmittedByTheMentee &&
          Boolean(respSession.frFeedbackSubmittedByTheMentee.length),
        isFeedbackSubmittedByMentor:
          respSession.frFeedbackSubmittedByTheMentor &&
          Boolean(respSession.frFeedbackSubmittedByTheMentor.length),
        isRecurring: isRecurring,
        recordingStatus: respSession.recordingStatus,
      };
      return getMeetingInfoMapper(meetingData, isV1Meet);
    }
  } catch (e) {
    console.error(e);
    logger.error("Error in getMeetingData", e);
  }
};
