import { DyteGrid } from "@dytesdk/react-ui-kit";
import { Flex } from "@chakra-ui/react";
import MyChatComponent from "../my-chat-component/my-chat-component";
import { useEffect, useState } from "react";
import bellWarn from "../../assets/bellWarn.svg";
import { useSelector } from "react-redux";
import ScheduleSessionPrompt from "../schedule-session-prompt/schedule-session-prompt";
import OfflinePhoneCallPopups from "../offline-phone-call-popups/offline-phone-call-popups";
import stateUtilityInstance from "../../singleton/global-state";
import BuildOpportunityReminder from "../BuildOpportunityReminder/BuildOpportunityReminder";
import minimizeIcon from "../../assets/minimize.svg";
import MinimizedStatePopup from "../BuildOpportunityReminder/MinimizedPopup";
import SessionEndPopUps from "../SessionEndPopUp/SessionEndPopUps";
import axios from "axios";
import moment from "moment";
import InstantCallPopup from "../instant-call-popup/instant-call-popup";

interface DyteContentProps {
  meeting: any;
  showChatContainer: boolean;
  states: any;
  onDyteStateUpdate: any;
  duration: any;
  hideOfflinePhoneCallPrompt: Function;
  showOfflinePhoneCallPopUp: boolean;
  showInstantCallPopUp: boolean;
  hideInstantCallPrompt: Function;
}
declare global {
  interface Window {
    Featurebase: any;
  }
}

const DyteContent = ({
  meeting,
  showChatContainer,
  states,
  onDyteStateUpdate,
  duration,
  hideOfflinePhoneCallPrompt,
  showOfflinePhoneCallPopUp,
  showInstantCallPopUp,
  hideInstantCallPrompt,
}: DyteContentProps) => {
  const meetingInfo = useSelector((state: any) => state.meetingInfo);
  const [beforeSessionEndsPops, setBeforeSessionEndsPops] = useState(false);
  const [mentorTaggingCard, setMentorTaggingCard] = useState(false);
  const [scheduleSessionPopUp, setScheduleSessionPopup] = useState(false);
  const [sessionDetails, setSessionDetails] = useState<any>({});
  const [isSessionStarted, setIsSessionStarted] = useState(false);
  const role = meetingInfo?.role;

  useEffect(() => {
    const fetchPhoneNumbers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL_DASHBOARD_API}packages/public/get-details-by-id?package_id=${meetingInfo.packageId}`
        );

        const phoneNumberFromMentors =
          response?.data?.["phoneNumber(FromMentors)"];
        const phoneNumberFromCandidates =
          response?.data?.["phoneNumber(FromCandidates)"];
        const sessionDateTimeFromAPI =
          response?.data?.["sessionDatetime(FromActiveSession)"];
        const meetingLinkFromAPI =
          response?.data?.["meetingLink(FromActiveSession)"];

        const dateObj = new Date(sessionDateTimeFromAPI);
        const formattedDate = dateObj.toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        const formattedTime = dateObj.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        });
        const formattedDateTime = `${formattedDate} ${formattedTime}`;

        const detialsOfSession = {
          phoneNumberFromMentors,
          phoneNumberFromCandidates,
          meetingLinkFromAPI,
          sessionDateTime: formattedDateTime,
        };

        // console.log("detialsOfSession", detialsOfSession);

        setSessionDetails(detialsOfSession);

        if (sessionDateTimeFromAPI) {
          const sessionStartTime = moment(formattedDateTime);
          const currentTime = moment();
          if (moment(sessionStartTime).isSameOrBefore(currentTime)) {
            setIsSessionStarted(true);
          }
        }
      } catch (error) {
        // console.error("Error fetching phone numbers:", error);
      }
    };

    if (meetingInfo.packageId) {
      fetchPhoneNumbers();
    }
  }, [meetingInfo.packageId]);

  useEffect(() => {
    const sessionStartInterval = setInterval(() => {
      const currentTime = moment();
      if (moment(sessionDetails?.sessionDateTime).isSameOrBefore(currentTime)) {
        setIsSessionStarted(true);
        clearInterval(sessionStartInterval);
      }
    }, 1000 * 60);

    return () => {
      clearInterval(sessionStartInterval);
    };
  }, [sessionDetails]);

  useEffect(() => {
    if (!beforeSessionEndsPops) {
      if (
        duration?.minutes >= 40 &&
        (scheduleSessionPopUp || mentorTaggingCard) &&
        meetingInfo?.role === "Mentor"
      ) {
        setBeforeSessionEndsPops(true);
      }
    }
  }, [duration, meetingInfo]);

  useEffect(() => {
    const mentorData: any =
      stateUtilityInstance.getPropertyByName("mentorData");

    if (
      meetingInfo &&
      !meetingInfo?.isRecurring &&
      meetingInfo?.packageName?.includes("Long")
    ) {
      setScheduleSessionPopup(true);
    }

    if (mentorData.linkedinTagging) {
      setMentorTaggingCard(true);
    }
  }, []);

  useEffect(() => {
    //  for mentees
    const initializeMenteeFeaturebase = () => {
      const scriptId = "featurebase-sdk-mentee";

      if (!document.getElementById(scriptId)) {
        const script = document.createElement("script");
        script.id = scriptId;
        script.src = "https://do.featurebase.app/js/sdk.js";
        document.head.appendChild(script);

        script.onload = () => {
          console.log("Initializing Featurebase for Mentee");
          window.Featurebase("initialize_feedback_widget", {
            organization: "mentee",
            theme: "light",
            placement: "right",
            defaultBoard: "",
            metadata: null,
          });
        };
      } else {
        console.log("Featurebase script already exists for Mentee");
        window.Featurebase("initialize_feedback_widget", {
          organization: "mentee",
          theme: "light",
          placement: "right",
          defaultBoard: "",
          metadata: null,
        });
      }
    };

    // for mentors
    const initializeMentorFeaturebase = () => {
      const scriptId = "featurebase-sdk-mentor";

      if (!document.getElementById(scriptId)) {
        const script = document.createElement("script");
        script.id = scriptId;
        script.src = "https://do.featurebase.app/js/sdk.js";
        document.head.appendChild(script);

        script.onload = () => {
          console.log("Initializing Featurebase for Mentor");
          window.Featurebase("initialize_feedback_widget", {
            organization: "mentor",
            theme: "light",
            placement: "right",
            defaultBoard: "",
            metadata: null,
          });
        };
      } else {
        console.log("Featurebase script already exists for Mentor");
        window.Featurebase("initialize_feedback_widget", {
          organization: "mentor",
          theme: "light",
          placement: "right",
          defaultBoard: "",
          metadata: null,
        });
      }
    };

    console.log("Meeting Role:", role);

    if (role === "Mentor") {
      initializeMentorFeaturebase();
    } else if (role === "Mentee") {
      initializeMenteeFeaturebase();
    } else {
      console.warn("Unknown role:", role);
    }
  }, [role]);

  const hideScheduleSessionPopUp = () => {
    setScheduleSessionPopup(false);
  };

  return (
    <Flex height="100%" position="relative">
      <DyteGrid
        aspect-ratio="16:9"
        gap={8}
        meeting={meeting}
        states={states}
        onDyteStateUpdate={onDyteStateUpdate}
      />

      <MyChatComponent isOpen={showChatContainer} />

      {/* {showOfflinePhoneCallPopUp && isSessionStarted ? (
        <div className="absolute right-0 bottom-2 w-[370px] md:w-[420px] mr-[10px] md:mr-4">
          <OfflinePhoneCallPopups
            hideOfflinePhoneCallPrompt={hideOfflinePhoneCallPrompt}
            sessionDetails={sessionDetails}
          />
        </div>
      ) : null} */}

      {showInstantCallPopUp ? (
        <div className="absolute w-[258px] top-10 left-1/2 transform -translate-x-1/2  ">
          <InstantCallPopup
            showInstantCallPopUp={showInstantCallPopUp}
            hideInstantCallPrompt={hideInstantCallPrompt}
            sessionDetails={sessionDetails}
          />
        </div>
      ) : null}

      {beforeSessionEndsPops && (
        <SessionEndPopUps
          meetingInfo={meetingInfo}
          hideScheduleSessionPopUp={hideScheduleSessionPopUp}
          mentorTaggingCard={mentorTaggingCard}
          scheduleSessionPopUp={scheduleSessionPopUp}
          setMentorTaggingCard={setMentorTaggingCard}
          setBeforeSessionEndsPops={setBeforeSessionEndsPops}
          beforeSessionEndsPops={beforeSessionEndsPops}
        />
      )}
    </Flex>
  );
};

export default DyteContent;
