import { useDyteMeeting } from "@dytesdk/react-web-core";
import { DyteDialogManager, DyteSetupScreen } from "@dytesdk/react-ui-kit";

const JoinMeeting = () => {
  const { meeting } = useDyteMeeting();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        background: "black",
        color: "white",
      }}
    >
      <DyteSetupScreen meeting={meeting} />
      <DyteDialogManager meeting={meeting} />
    </div>
  );
};

export default JoinMeeting;
