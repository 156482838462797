import { useEffect, useState } from "react";

const useSummaryPopupHook = () => {
  const [showSummaryPopUp, setShowSummaryPopUp] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //       setShowSummaryPopUp(true);
  //   }, 1000 * 3);
  // }, []);

  const handleTogglePopUp = () => {
    setShowSummaryPopUp(!showSummaryPopUp);
  };

  return {
    showSummaryPopUp,
    handleTogglePopUp,
  };
};

export default useSummaryPopupHook;
