import { Logtail } from "@logtail/browser";
import { store } from "./redux/store";

const logtail = new Logtail(
  process.env.REACT_APP_BETTERSTACK_SOURCE_TOKEN || ""
);

let meetingInfo = null as any;
const handleStateChange = () => {
  meetingInfo = store.getState().meetingInfo;
};
const unsubscribe = store.subscribe(handleStateChange);

export const logger = {
  warn: (message: string, additionalData: any = {}) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    try {
      const properties = {
        ...additionalData,
        timezone,
        ...(meetingInfo && { meetingInfo }),
      };
      logtail.warn(message, properties);
    } catch (error) {
      console.error("Error in logger.warn:", error);
    }
  },
  info: (message: string, additionalData: any = {}) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    try {
      const properties = {
        ...additionalData,
        timezone,
        ...(meetingInfo && { meetingInfo }),
      };
      logtail.info(message, properties);
    } catch (error) {
      console.error("Error in logger.info:", error);
    }
  },
  error: (message: string, additionalData: any = {}) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    try {
      const properties = {
        ...additionalData,
        timezone,
        ...(meetingInfo && { meetingInfo }),
      };
      logtail.error(message, properties);
    } catch (error) {
      console.error("Error in logger.error:", error);
    }
  },
};
