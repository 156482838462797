import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { ReactComponent as XIcon } from "../../assets/x.svg";
import { ReactComponent as Triangle } from "../../assets/triangle-vector.svg"

type SendMentorShipPlanPopUpProp = {
  handleMentorshipPlanPopup: Function
  showMentorshipPlanPopUp: boolean
  showShareMentorShipPlanInfoPopUp: boolean
  handleShareMentorShipPlanInfoPopUp: Function
}

const SendMentorShipPlanPopUp = ({ showMentorshipPlanPopUp, handleMentorshipPlanPopup, showShareMentorShipPlanInfoPopUp, handleShareMentorShipPlanInfoPopUp }: SendMentorShipPlanPopUpProp) => {
  const meetingInfo = useSelector((state: any) => state.meetingInfo);
  return (
    <>
      {
        showMentorshipPlanPopUp ?
          <div className="h-[90vh] w-full sm:w-2/3 md:1/2 xl:w-[36%] overflow-auto absolute top-12 z-10 right-0 sm:right-5 p-5 bg-white rounded-lg flex-col justify-start items-start gap-3 inline-flex">
            <div onClick={() => { handleMentorshipPlanPopup() }} className='cursor-pointer absolute top-1.5 right-2'>
              <XIcon />
            </div>
            <iframe
              src={`https://mentor.preplaced.${process.env.REACT_APP_SERVER === "production" ? "in" : "dev"}/dashboard/feedback/${meetingInfo.packageId}?openSharePlan=true`}
              title="Post Syncup Feedback Mentor"
              style={{ height: "100%", width: "100%", overflow: "auto" }}
            ></iframe>
          </div >
          : showShareMentorShipPlanInfoPopUp ?
            <div className="absolute top-16 z-10 right-30 w-[180px] md:w-[220px]  px-4 py-3 bg-white rounded-lg flex-col justify-start items-start gap-3 inline-flex">
              <div className="self-stretch relative flex-col justify-start items-start gap-2 flex">
                <div className="self-stretch justify-between items-center inline-flex">
                  <div className="text-[#1570EF] text-xs font-semibold">
                    Send A Mentorship Plan
                  </div>
                  <div
                    className="w-5 h-5 relative cursor-pointer"
                    onClick={() => { handleShareMentorShipPlanInfoPopUp() }}
                  >
                    <XIcon />
                  </div>
                </div>
                <p className="text-xs text-gray-500">Sending a mentorship plan increases the chance of a trial conversion by upto 30%</p>
              </div>
              <div className="absolute -top-[14px] right-[21px]">
                <Triangle />
              </div>
            </div>
            : null
      }


    </>
  )
}

export default SendMentorShipPlanPopUp