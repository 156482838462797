import React, { useEffect, useState } from "react";
import { DyteControlbarButton } from "@dytesdk/react-ui-kit";
import { chatIcon, closeIcon } from "../icons/icons";
import Talk from "talkjs";
import { useSelector } from "react-redux";
import { logger } from "../../logtail";

interface ChatButonPropType {
  showChatContainer: boolean;
  toggleDyteChatWithModal: () => void;
}

const ChatButton: React.FC<ChatButonPropType> = ({
  showChatContainer,
  toggleDyteChatWithModal,
}) => {
  const [unreadMessages, setUnreadMessages] = useState<any>("");

  const meetingInfo = useSelector((state: any) => state.meetingInfo);

  const {
    menteeName,
    menteeId,
    menteeEmail,
    mentorName,
    mentorEmail,
    mentorId,
    role,
  } = meetingInfo;

  var meProperties: any = {
    id: role === "Mentor" ? mentorId : menteeId,
    name: role === "Mentor" ? mentorName : menteeName,
    email: role === "Mentor" ? mentorEmail : menteeEmail,
    // phone: "",
    role: role,
  };

  const conversationId = `${mentorId}_${menteeId}`;

  const fetchTalkSession = async () => {
    try {
      Talk.ready
        .then(() => {
          const me = new Talk.User(meProperties);
          if (!(window as any).talkSession) {
            (window as any).talkSession = new Talk.Session({
              appId: process.env.REACT_APP_TALKJS_APP_ID as string,
              me,
            });
          }
          (window as any).talkSession.unreads.on(
            "change",
            (unreadConversations: any) => {
              const conversation = unreadConversations.filter(
                (item: any) => item.conversation.id === conversationId
              );
              const unreadLength = conversation
                ? conversation[0].unreadMessageCount
                  ? conversation[0].unreadMessageCount
                  : ""
                : "";
              setUnreadMessages(unreadLength);
            }
          );
        })
        .catch((e) => {
          console.error(e);
          logger.error("Error in TalkJS setup:", e);
        });
    } catch (e) {
      console.error(e);
      logger.error("Error in fetchTalkSession:", e);
    }
  };

  useEffect(() => {
    fetchTalkSession();
  }, []);

  return (
    <div className="relative">
      <span
        style={{ zIndex: 1 }}
        className="absolute -top-1 right-[30px] bg-black leading-[1] rounded-full"
      >
        {unreadMessages}
      </span>

      <DyteControlbarButton
        label="Chat"
        icon={!showChatContainer ? chatIcon : closeIcon}
        onClick={toggleDyteChatWithModal}
      />
    </div>
  );
};

export default ChatButton;
