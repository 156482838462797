export const loaderTextListOnboarding = [
    "Validating Meeting Link",
    "Validating Meeting Link.",
    "Validating Meeting Link..",
    "Validating Meeting Link...",
    "Fetching Meeting Data",
    "Fetching Meeting Data.",
    "Fetching Meeting Data..",
    "Fetching Meeting Data...",
];
