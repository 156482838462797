import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';

const UseHeader = ({ duration }: any) => {
  const [showMentorshipPlanPopUp, setShowMentorshipPlanPopUp] = useState(false)
  const [showSendMentorshipButton, setShowMentorshipButton] = useState(false)
  const [showShareMentorShipPlanInfoPopUp, setShowShareMentorShipPlanInfoPopUp] = useState(false)
  const meetingInfo = useSelector((state: any) => state.meetingInfo);
  // for showing showShareMentorShipPlanInfoPopUp first time only when duartion is started (means both mentor & mentee has joined)
  const hasShownMentorShipPlanInfoPopUp = useRef(false);


  useEffect(() => {

    if (!showSendMentorshipButton && meetingInfo?.packageName?.includes("Trial") && meetingInfo?.role === "Mentor" && (duration?.hours > 0 || duration.minutes > 0 || duration.seconds > 0))
      setShowMentorshipButton(true)
    if (meetingInfo &&
      duration?.minutes === 10 && duration?.seconds === 0 &&
      meetingInfo?.role === "Mentor" &&
      meetingInfo?.packageName?.includes("Trial"))
      setShowShareMentorShipPlanInfoPopUp(true)
  }, [duration, meetingInfo])

  useEffect(() => {
    if (!hasShownMentorShipPlanInfoPopUp?.current && (duration?.hours > 0 || duration.minutes > 0 || duration.seconds > 0)) {
      if (meetingInfo?.role === "Mentor" && meetingInfo?.packageName?.includes("Trial")) setShowShareMentorShipPlanInfoPopUp(true)
      hasShownMentorShipPlanInfoPopUp.current = true
    }
  }, [duration, meetingInfo])

  const handleMentorshipPlanPopup = () => {
    setShowShareMentorShipPlanInfoPopUp(false)
    setShowMentorshipPlanPopUp(!showMentorshipPlanPopUp)
  }

  const handleShareMentorShipPlanInfoPopUp = () => { setShowShareMentorShipPlanInfoPopUp(!showShareMentorShipPlanInfoPopUp) }

  return (
    {
      showSendMentorshipButton,
      showMentorshipPlanPopUp,
      handleMentorshipPlanPopup,
      showShareMentorShipPlanInfoPopUp,
      handleShareMentorShipPlanInfoPopUp

    }
  )
}

export default UseHeader