import { useCallback, useEffect, useState, useRef } from "react";
import { useDyteClient, DyteProvider } from "@dytesdk/react-web-core";
import MeetingRender from "../../components/on-going-meeting-comp/ongoing-meeting-comp";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loader/Loader";
import { postCall } from "../../apiServices/apiServices";
import {
  setDeviceInfo,
  setMeetingStatus,
} from "../../redux/reducers/meetingReducer/meetingSlice";
import { trackAnalytics } from "../../analytics/analyticsApi";
import { commonProperties } from "../../hooks/useCommonProperties";
import axios from "axios";
import moment from "moment";
import { logger } from "../../logtail";

const loaderTextListMeetingInit = [
  "Loading Meeting.",
  "Loading Meeting..",
  "Loading Meeting...",
  "Loading Meeting.",
  "Loading Meeting..",
  "Loading Meeting...",
  "Loading Meeting.",
  "Loading Meeting..",
  "Loading Meeting...",
];

const { REACT_APP_BASE_URL_DASHBOARD_API } = process.env;

const MeetingInit = () => {
  // dispatch
  const dispatch = useDispatch();
  // Dyte Meeting
  const [meeting, initClient] = useDyteClient();
  // console.log(meeting, "meetingOngoing");
  const [bindEvents, setbindEvents] = useState(true);
  const participantPresent = useRef({
    Mentor: false,
    Mentee: false,
  });

  // Data of Meeting like menteee/mentor Name, authToken, feedbackForm URL, role etc...
  const meetingInfo = useSelector((state: any) => state.meetingInfo);

  // getting roomID from URL
  let params: {
    id: string;
    room: string;
  } = useParams();

  var deviceInfo: any;

  useEffect(() => {
    // console.log("initi client started", params.room, meetingInfo);
    let initClientObj: any = {
      authToken: meetingInfo.authToken,
      // roomName: params.room,
      defaults: {
        audio: true, // Disable user's audio by default
        video: true, // Enable user's video by default
      },
      // modules: {
      //   devTools: {
      //     logs: false,
      //     plugins: [
      //       {
      //         id: "de42dca2-4204-4911-a041-e4822b1b10e7",
      //         name: "Testing Trial Doc Plugin",
      //         port: 5000,
      //       },
      //     ],
      //   },
      // },
    };
    if (meetingInfo.isV1Meet) {
      initClientObj = {
        roomName: params.room,
        ...initClientObj,
      };
    }
    initClient(initClientObj);
  }, []);

  const startRecording = async (m: any, startRecording: boolean) => {
    if (startRecording && meetingInfo.recordingStatus !== "False") {
      await m.recording.start();
      try {
        const reqBodySessionLeft = {
          user_id: meetingInfo.mentorEmail,
          event: "Recording Started",
          properties: {
            ...commonProperties(meetingInfo),
            ...deviceInfo,
          },
        };
        trackAnalytics(reqBodySessionLeft);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const getItemFromLocalStorage = (key: string): any => {
    let data = window.localStorage.getItem(key);
    return data;
  };

  const setItemToLocalStorage = (key: string, data: any) => {
    window.localStorage.setItem(key, JSON.stringify(data));
  };
  const [sessionStartDateTime, setSessionStartDateTime] = useState<any>(null);
  const [duration, setDuration] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    if (!sessionStartDateTime) return;

    const calculateDuration = () => {
      const endDate: any = new Date(); // Current date and time
      const startDate: any = new Date(sessionStartDateTime); // Current date and time()
      const timeDifference = endDate - startDate;
      const millisecondsInSecond = 1000;
      const millisecondsInMinute = 60 * millisecondsInSecond;
      const millisecondsInHour = 60 * millisecondsInMinute;

      const hours = Math.floor(timeDifference / millisecondsInHour);
      const minutes = Math.floor(
        (timeDifference % millisecondsInHour) / millisecondsInMinute
      );
      const seconds = Math.floor(
        (timeDifference % millisecondsInMinute) / millisecondsInSecond
      );

      setDuration({ hours, minutes, seconds });
    };

    const intervalId = setInterval(calculateDuration, 1000); // Update every 1 second

    return () => clearInterval(intervalId);
  }, [sessionStartDateTime]);

  const storeDataInLocalStorage = () => {
    let sessionsData;
    let key = "sessionsData";
    // get data from localStorage
    sessionsData = JSON.parse(getItemFromLocalStorage(key) || false);

    if (sessionsData[params?.room]) {
      let sessionStartDateTime = sessionsData[params?.room];
      // Subtract 1.5 hour from the current date and time
      const oneAndAHalfHoursAgo = moment().subtract(1.5, "hours");
      const meetingStartedDateTime = moment(sessionStartDateTime);
      if (meetingStartedDateTime.isBefore(oneAndAHalfHoursAgo)) {
        let data = {
          [params?.room]: new Date().toISOString(),
        };

        setItemToLocalStorage(key, data);

        setSessionStartDateTime(data[params?.room]);
      } else {
        setSessionStartDateTime(sessionStartDateTime);
      }
    } else {
      //setting data to the localStorage
      let data = {
        [params?.room]: new Date().toISOString(),
      };

      setItemToLocalStorage(key, data);

      setSessionStartDateTime(data[params?.room]);
    }
  };

  const [showOfflinePhoneCallPopUp, setShowOfflinePhoneCallPopUp] =
    useState(false);

  const showOfflinePhoneCallPrompt = () => {
    setShowOfflinePhoneCallPopUp(true);
  };

  const hideOfflinePhoneCallPrompt = () => {
    setShowOfflinePhoneCallPopUp(false);
  };

  const offlinePhoneCallPopUp = (callBackFunction: any) => {
    let cacheTimeOut: any = false;
    return () => {
      if (cacheTimeOut) {
        clearTimeout(cacheTimeOut);
        hideOfflinePhoneCallPrompt();
      } else {
        cacheTimeOut = setTimeout(callBackFunction, 60 * 1000 * 3);
      }
    };
  };

  const showOfflinePhoneCallClosure = offlinePhoneCallPopUp(
    showOfflinePhoneCallPrompt
  );

  /******************* For Showing Instant Call PopUp **************************/

  const [showInstantCallPopUp, setShowInstantCallPopUp] = useState(false);
  const showInstantCallPrompt = () => {
    setShowInstantCallPopUp(true);
  };

  const hideInstantCallPrompt = () => {
    setShowInstantCallPopUp(false);
  };

  const instantCallPopUp = (callBackFunction: any) => {
    let cacheTimeOut: any = false;
    return () => {
      if (cacheTimeOut) {
        clearTimeout(cacheTimeOut);
        hideInstantCallPrompt();
      } else {
        cacheTimeOut = setTimeout(callBackFunction, 1000 * 60 * 2);
      }
    };
  };

  const showInstantCallClosure = instantCallPopUp(showInstantCallPrompt);

  const fetchAdditionalProperties = async (packageId: any) => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_URL_DASHBOARD_API}packages/public/get-details-by-id?package_id=${packageId}`
      );
      const totalTrialCount =
        response?.data?.["totalTrialCount(FromCandidates)"];
      const name = response?.data?.["name(FromRelationshipManagers)"];
      const sessionDateTimeFromAPI =
        response?.data?.["sessionDatetime(FromActiveSession)"];

      const dateObj = new Date(sessionDateTimeFromAPI);
      const formattedDate = dateObj.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      const formattedTime = dateObj.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      });
      const formattedDateTime = `${formattedDate} ${formattedTime}`;

      return { totalTrialCount, name, sessionDateTime: formattedDateTime };
    } catch (error) {
      console.error("Error fetching additional properties:", error);
      return { totalTrialCount: null, name: null, sessionDateTime: null };
    }
  };

  const setParticipantPresent = useCallback(
    async (clientSpecificId: any, m: any) => {
      const userType = clientSpecificId.split("_")[0];
      const change = { ...participantPresent.current, [userType]: true };
      //Offline Phone Call PopUp

      /* 
      Create a SetTimout of 20 Sec When Only One Participant Joined 
      */
      // showOfflinePhoneCallClosure();
      showInstantCallClosure();

      if (change.Mentor && change.Mentee) {
        /* Clear the SetTimout When Both Joined */
        // showOfflinePhoneCallClosure();
        showInstantCallClosure();
        /* -------------------------------------------------------------------------- */
        /*             store sessions startedDatetime when both are joined            */
        /* -------------------------------------------------------------------------- */
        storeDataInLocalStorage();
        /* -------------------------------------------------------------------------- */
        /*                         Analytics: Session Started                         */
        /* -------------------------------------------------------------------------- */
        if (meetingInfo.role === "Mentor") {
          /* -------------------------------------------------------------------------- */
          /*                       Mark Attendance POST Call start                      */
          /* -------------------------------------------------------------------------- */
          sessionStarted();

          /* -------------------------------------------------------------------------- */
          /*                   Start Recording only when both join                      */
          /* -------------------------------------------------------------------------- */
          if (
            m.recording.recordingState !== "RECORDING" &&
            meetingInfo.plan !== "Basic"
          ) {
            startRecording(m, true);
          }

          const additionalProperties = await fetchAdditionalProperties(
            meetingInfo.packageId
          );

          //  console.log("Fetched totalTrialCount:", additionalProperties.totalTrialCount);
          //   console.log("Fetched name:", additionalProperties.name);

          const reqBodySessionStarted: any = {
            event: "Session Started",
            properties: {
              ...commonProperties(meetingInfo),
              ...meeting?.self.device,
              totalTrialCount: additionalProperties.totalTrialCount,
              sessionDateTime: additionalProperties.sessionDateTime,
            },
          };
          //  console.log('started', reqBodySessionStarted)
          // sending data to mentor
          reqBodySessionStarted["user_id"] = meetingInfo.mentorEmail;
          trackAnalytics(reqBodySessionStarted);

          // sending data to mentee
          reqBodySessionStarted["user_id"] = meetingInfo.menteeEmail;
          trackAnalytics(reqBodySessionStarted);
        }
      }
      participantPresent.current = change;
    },
    [participantPresent]
  );

  const markAttendance = async (role: any) => {
    const postCallBodyData: any = {
      recordId: meetingInfo.sessionId,
    };
    if (role === "Mentor") {
      postCallBodyData["attendedByMentor"] = true;
    }

    if (role === "Mentee") {
      postCallBodyData["attendedByCandidate"] = true;
    }

    var objectLength = Object.keys(postCallBodyData).length === 2;

    if (objectLength) {
      try {
        await postCall({
          url: `${REACT_APP_BASE_URL_DASHBOARD_API}sessions/public`,
          data: postCallBodyData,
          contentType: "application/json",
        });
      } catch (error) {
        logger.error(`Error marking attendance for ${role}: ${error}`);
      }
    }
  };

  const sessionStarted = () => {
    var meetScreenAndStatus = {
      currentScreen: "Meeting Ongoing",
      meetingStatus: "Started",
    };
    const postCallBodyData: any = {
      recordId: meetingInfo.sessionId,
      sessionStarted: true,
    };
    postCall({
      url: `${REACT_APP_BASE_URL_DASHBOARD_API}sessions/public`,
      data: postCallBodyData,
      contentType: "application/json",
    });
    dispatch(setMeetingStatus(meetScreenAndStatus));
  };

  const analyticsMeetingJoinedByParticipant = () => {
    if (meeting && bindEvents) {
      //device Info
      deviceInfo = {
        ...meeting?.self.device,
      };
      // camelCase to underscore
      for (let property in deviceInfo) {
        var new_key = property
          .replace(/\.?([A-Z]+)/g, function (x, y) {
            return "_" + y.toLowerCase();
          })
          .replace(/^_/, "");
        deviceInfo[new_key] = deviceInfo[property];
        delete deviceInfo[property];
      }

      // Analytics of Session Joined
      const reqBodySessionJoined = {
        user_id:
          meetingInfo.role === "Mentor"
            ? meetingInfo.mentorEmail
            : meetingInfo.menteeEmail,
        event:
          meetingInfo.role === "Preplaced"
            ? "Session Joined By Preplaced"
            : "Session Joined",
        properties: {
          ...commonProperties(meetingInfo),
          ...deviceInfo,
          audio_enabled: meeting.self.audioEnabled,
          video_enabled: meeting.self.videoEnabled,
        },
      };
      trackAnalytics(reqBodySessionJoined);
    }
  };

  useEffect(() => {
    if (meeting && bindEvents) {
      dispatch(setDeviceInfo(meeting?.self.device));
      meeting.self.on("roomJoined", () => {
        var clientSpecificId = meeting.self.clientSpecificId;
        const userType = clientSpecificId.split("_")[0];
        // console.log("userType", userType);
        markAttendance(userType);
        setParticipantPresent(clientSpecificId, meeting);
        /* -------------------------------------------------------------------------- */
        /*                        Analytics:Participant Joined                        */
        /* -------------------------------------------------------------------------- */
        analyticsMeetingJoinedByParticipant();
      });

      meeting.participants.joined.on(
        "participantJoined",
        (participant: any) => {
          const userType = participant.clientSpecificId.split("_")[0];
          markAttendance(userType);
          setParticipantPresent(participant.clientSpecificId, meeting);
        }
      );

      meeting.self.on("roomLeft", () => {
        var meetScreenAndStatus = {
          currentScreen: "Feedback Form",
          meetingStatus: "Completed",
        };
        dispatch(setMeetingStatus(meetScreenAndStatus));
        /* -------------------------------------------------------------------------- */
        /*                         Analytics: Participant Left                        */
        /* -------------------------------------------------------------------------- */
        const reqBodySessionLeft = {
          user_id:
            meetingInfo.role === "Mentor"
              ? meetingInfo.mentorEmail
              : meetingInfo.menteeEmail,
          event:
            meetingInfo.role === "Preplaced"
              ? "Session Left By Preplaced"
              : "Session Left",
          properties: {
            ...commonProperties(meetingInfo),
            ...deviceInfo,
            audio_enabled: meeting.self.audioEnabled,
            video_enabled: meeting.self.videoEnabled,
          },
        };
        trackAnalytics(reqBodySessionLeft);
      });
      setbindEvents(false);
    }
  }, [meeting]);
  console.log("meeting", meeting);

  return (
    <>
      {meeting ? (
        <DyteProvider value={meeting} fallback={undefined}>
          <div style={{ height: "100vh", width: "100vw", color: "#fff" }}>
            <MeetingRender
              duration={duration}
              showOfflinePhoneCallPopUp={showOfflinePhoneCallPopUp}
              hideOfflinePhoneCallPrompt={hideOfflinePhoneCallPrompt}
              showInstantCallPopUp={showInstantCallPopUp}
              hideInstantCallPrompt={hideInstantCallPrompt}
            />
            {/* <div>
              <p>
                Duration: {duration.hours} hours, {duration.minutes} minutes,{" "}
                {duration.seconds} seconds
              </p>
            </div> */}
          </div>
        </DyteProvider>
      ) : (
        <Loading loaderTextList={loaderTextListMeetingInit} />
      )}
    </>
  );
};

export default MeetingInit;
