import { Box, Heading } from "@chakra-ui/react";
import React, { useState } from "react";


interface CardProps {
  logo?: any;
  heading?: string;
  backgroundColor?: string;
  borderRadius?: string;
  color?: string;
  hoverBackGrounfColor?: string;
  handleSubmit?: () => void;
  role?: string;
}

const Card = ({
  logo,
  heading,
  backgroundColor,
  hoverBackGrounfColor,
  color,
  handleSubmit,
  role
}: CardProps) => {
  const [changeColorOnHover, setChangeColorOnHover] = useState(false);
  return (
    <>
      <Box
        onClick={handleSubmit}
        onMouseEnter={() => setChangeColorOnHover(true)}
        onMouseLeave={() => setChangeColorOnHover(false)}
        _hover={{
          backgroundColor: hoverBackGrounfColor,
          color: "white",
        }}
        color={color}
        cursor="pointer"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        borderRadius="6px"
        backgroundColor={backgroundColor}
        w="100%"
        h="200px"
      >
        {logo({color: changeColorOnHover})}
        <Heading marginTop="1.5rem" as="h3" size="sm">
          {heading}
        </Heading>
      </Box>
    </>
  );
};

export default Card;
