import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Talk from "talkjs";

const useUnreadMessages = () => {
  const [unreadMessages, setUnreadMessages] = useState<number>(0);

  const meetingInfo = useSelector((state: any) => state.meetingInfo);

  const {
    menteeName,
    menteeId,
    menteeEmail,
    mentorName,
    mentorEmail,
    mentorId,
    role,
  } = meetingInfo;

  var meProperties: any = {
    id: role === "Mentor" ? mentorId : menteeId,
    name: role === "Mentor" ? mentorName : menteeName,
    email: role === "Mentor" ? mentorEmail : menteeEmail,
    // phone: "",
    role: role,
  };

  const conversationId = `${mentorId}_${menteeId}`;

  const fetchTalkSession = async () => {
    try {
      Talk.ready
        .then(() => {
          const me = new Talk.User(meProperties);
          if (!(window as any).talkSession)
            (window as any).talkSession = new Talk.Session({
              appId: process.env.REACT_APP_TALKJS_APP_ID as string,
              me,
            });
          (window as any).talkSession.unreads.on(
            "change",
            (unreadConversations: any) => {
              console.log("unreads: ", unreadConversations);
              const conversation = unreadConversations.filter(
                (item: any) => item.conversation.id === conversationId
              );
              const unreadLength = conversation
                ? conversation[0]?.unreadMessageCount
                : 0;
              setUnreadMessages(unreadLength);
            }
          );
        })
        .catch((e) => console.error(e));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchTalkSession();
  }, []);

  return { unreadMessages };
};

export default useUnreadMessages;
