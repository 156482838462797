import {
  DyteLogo,
  DyteMeetingTitle,
  DyteClock,
  DyteParticipantCount,
  DyteRecordingIndicator,
} from "@dytesdk/react-ui-kit";
import { Flex, Box, Center, Divider, Hide, Show } from "@chakra-ui/react";
import Logo from "../../assets/logo.svg";
import SummaryPopup from "../summary-popup/summary-popup";
import { useSelector } from "react-redux";
import SendMentorshipButton from "../send-mentorship-button/send-mentorship-button";
import { useEffect, useState } from "react";
import SendMentorShipPlanPopUp from "../send-mentorship-plan-popup/send-mentorship-plan-popup";
import UseHeader from "./use-header";
import ChatButton from "../chat-button/chat-button";

interface HeaderProps {
  meeting: any;
  duration: any
  showChatContainer: boolean;
  toggleDyteChat: () => void;
}

const Header = ({ meeting, duration, showChatContainer,
  toggleDyteChat, }: HeaderProps) => {
  const meetingInfo = useSelector((state: any) => state.meetingInfo);
  const { sessionType } = meetingInfo;
  const { showSendMentorshipButton, showMentorshipPlanPopUp, handleMentorshipPlanPopup, showShareMentorShipPlanInfoPopUp, handleShareMentorShipPlanInfoPopUp } = UseHeader({ duration })

  return (
    <>
      <Box padding="3.5px 14px">
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex
            h="40px"
            flexDirection="row"
            alignItems="center"
          >
            <DyteLogo
              style={{ width: "22px" }}
              config={{
                designTokens: { logo: Logo },
              }}
            />

            <Center
              height="22px"
              p="0 10px"
            >
              <Divider orientation="vertical" />
            </Center>

            <Hide below="lg">
              <DyteMeetingTitle
                style={{ fontSize: "14px" }}
                meeting={meeting}
              />
            </Hide>

            <Show below="lg">
              <ChatButton
                showChatContainer={showChatContainer}
                toggleDyteChatWithModal={toggleDyteChat}
              />
            </Show>

            <DyteRecordingIndicator meeting={meeting} />
          </Flex>
          <Flex
            flexDirection="row"
            alignItems="center"
          >
            {sessionType.includes("Sync") ? (
              <>
                <SummaryPopup />
                <Center
                  height="22px"
                  p="0 10px"
                >
                  <Divider orientation="vertical" />
                </Center>
              </>
            ) : null}
            {showSendMentorshipButton ? <SendMentorshipButton handleMentorshipPlanPopup={handleMentorshipPlanPopup} /> : null}
            <SendMentorShipPlanPopUp
              showMentorshipPlanPopUp={showMentorshipPlanPopUp}
              handleMentorshipPlanPopup={handleMentorshipPlanPopup}
              showShareMentorShipPlanInfoPopUp={showShareMentorShipPlanInfoPopUp}
              handleShareMentorShipPlanInfoPopUp={handleShareMentorShipPlanInfoPopUp}
            />
            <DyteParticipantCount meeting={meeting} />
            <Center
              height="22px"
              p="0 10px"
            >
              <Divider orientation="vertical" />
            </Center>
            <DyteClock meeting={meeting} />
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default Header;
