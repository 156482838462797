// import {
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalHeader,
//   ModalBody,
//   ModalCloseButton,
//   useDisclosure,
// } from "@chakra-ui/react";
// import {
//   Accordion,
//   AccordionItem,
//   AccordionButton,
//   AccordionPanel,
//   AccordionIcon,
// } from "@chakra-ui/react";
// import { Box, Text, Divider, Image, Button, Link } from "@chakra-ui/react";
import { Fragment } from "react";
// import { useSelector } from "react-redux";
// import FacingProblemIcon from "../../assets/facing-problem.svg";
// import ModalFacingProblemIcon from "../../assets/modal-facing-problem.svg";
// import { trackAnalytics } from "../../analytics/analyticsApi";
// import customReqBody from "../../utils/customReqBody";


  // const meetingInfo = useSelector((state: any) => state.meetingInfo);

  // const Linktag = (text: any) => (
  //   <Link
  //     color="blue.500"
  //     fontWeight="bold"
  //     target={isNaN(parseInt(text)) ? "_self" : "_blank"}
  //     href={isNaN(parseInt(text)) ? "" : `tel:${text}`}
  //     textDecoration="underline"
  //     onClick={isNaN(parseInt(text)) ? rejoinClicked : numberClicked}
  //   >
  //     {text}
  //   </Link>
  // );

  // const relationshipManagerName =
  //   meetingInfo.relationshipManagerName || "Himna Shaikh";
  // const relationshipManagerPhoneNumber =
  //   meetingInfo.relationshipManagerPhoneNumber || "+918591481684";

  // const seeds = [
  //   {
  //     id: 1,
  //     title: "Audio Issues",
  //     message: (
  //       <p>
  //         Check mic settings If you are unable to hear the other person or they
  //         are unable to hear you despite the mic being unmute on both ends (Go
  //         to settings &gt; Click on audio &gt; Select device for microphone). If
  //         the problem still exists then the person unable to hear the other
  //         person should {Linktag("rejoin")} the session.
  //       </p>
  //     ),
  //   },
  //   {
  //     id: 2,
  //     title: "Video Issues",
  //     message: (
  //       <p>
  //         If you cannot view the video of the other person, request them to
  //         switch off and switch on their camera again. If the problem still
  //         exists then the person unable to view the other person should{" "}
  //         {Linktag("rejoin")} the session.
  //       </p>
  //     ),
  //   },
  //   {
  //     id: 3,
  //     title: "Mentor/Mentee didn't join the session",
  //     message: (
  //       <p>
  //         We request you to wait for up to 5 minutes if your mentor or mentee
  //         has not joined the session yet. After that please contact
  //         <Text as="span" color="blue.500" fontWeight="bold">
  //           {" "}
  //           {relationshipManagerName}
  //         </Text>
  //         .
  //         <br />
  //         Contact&nbsp;info:&nbsp;
  //         {Linktag(relationshipManagerPhoneNumber)}
  //       </p>
  //     ),
  //   },
  //   {
  //     id: 4,
  //     title: "Mentor/Mentee got disconnected",
  //     message: (
  //       <p>
  //         Please contact
  //         <Text as="span" color="blue.500" fontWeight="bold">
  //           {" "}
  //           {relationshipManagerName}
  //         </Text>{" "}
  //         if your mentor or mentee has got disconnected and has not joined back.
  //         <br />
  //         Contact&nbsp;info:&nbsp;
  //         {Linktag(relationshipManagerPhoneNumber)}
  //       </p>
  //     ),
  //   },
  //   {
  //     id: 5,
  //     title: "Screen share is not working",
  //     message: (
  //       <p>
  //         Retry after clicking the screen share button (stop sharing and then
  //         click on screen share again). In case the button is not clickable,
  //         please {Linktag("rejoin")} the session.
  //       </p>
  //     ),
  //   },
  //   {
  //     id: 6,
  //     title: "Whiteboard is not working",
  //     message: (
  //       <p>
  //         Close the whiteboard and try opening it again. In case the button is
  //         not clickable, please {Linktag("rejoin")} the session.
  //       </p>
  //     ),
  //   },
  //   {
  //     id: 7,
  //     title: "Code-Rough work is not working",
  //     message: (
  //       <p>
  //         Close Code-Rough-Work and try opening it again. In case the button is
  //         not clickable, please {Linktag("rejoin")} the session.
  //       </p>
  //     ),
  //   },
  //   {
  //     id: 8,
  //     title: "Planner is not working",
  //     message: (
  //       <p>
  //         The planner would be opened in a new tab and you will be required to
  //         log in (if not done previously). If the button does not open the
  //         planner then you can also access it from the calendar invite shared
  //         with you. In case the button isn't clickable, please{" "}
  //         {Linktag("rejoin")} the session.
  //       </p>
  //     ),
  //   },
  //   {
  //     id: 9,
  //     title: "Unable to join meeting",
  //     message: (
  //       <p>
  //         Check your network connection and make sure you are connected to a
  //         network with a minimum speed of 1 Mbps. If the problem still exists,
  //         please {Linktag("rejoin")} the meeting.
  //       </p>
  //     ),
  //   },
  //   {
  //     id: 10,
  //     title: "Unable to submit feedback",
  //     message: (
  //       <p>
  //         Check if you have filled all the required fields correctly in the form
  //         and then resubmit. If the problem still exists then please contact
  //         <Text as="span" color="blue.500" fontWeight="bold">
  //           {" "}
  //           {relationshipManagerName}
  //         </Text>
  //         .
  //         <br />
  //         Contact&nbsp;info:&nbsp;
  //         {Linktag(relationshipManagerPhoneNumber)}
  //       </p>
  //     ),
  //   },
  // ];

  // const { isOpen, onOpen, onClose } = useDisclosure();

  // const modalOpenClicked = () => {
  //   const modalOpenData = customReqBody("Support Button Clicked", meetingInfo);
  //   trackAnalytics(modalOpenData);
  // };

  // const modalCloseClicked = () => {
  //   const modalClosedData = customReqBody("Support Panel Closed", meetingInfo);
  //   trackAnalytics(modalClosedData)
  // };

  // const modalOptionClicked = (title: any) => {
  //   var args: any = { option: title };
  //   const modalOptionData = customReqBody(
  //     "Support Option Expanded",
  //     meetingInfo,
  //     args
  //   );
  //   trackAnalytics(modalOptionData);
  // };

  // function rejoinClicked() {
  //   const modalRejoinData = customReqBody("Rejoin Button Clicked", meetingInfo);
  //   trackAnalytics(modalRejoinData)
  // }

  // function numberClicked() {
  //   const modalNumberData = customReqBody("POC Number Clicked", meetingInfo);
  //   trackAnalytics(modalNumberData)
  // }

  // const scrollRef = useRef<null | HTMLDivElement>(null);
  // const executeScroll = () =>
  //   scrollRef?.current?.scrollIntoView({
  //     behavior: "smooth",
  //     inline: "end",
  //   });
  const SupportButton = () => {
  return (
    <>

{/* 
<button data-featurebase-feedback>
  Open Widget
</button> */}
      {/* {!isOpen && (
        <Button
          display={"flex"}
          gap={"0.5rem"}
          variant={"solid"}
          color={"white"}
          colorScheme={"red.400"}
          backgroundColor={"red.400"}
          borderRadius={"8px 8px 0 0"}
          position={"absolute"}
          top={"50%"}
          right={"-2.8rem"}
          transform={"rotateZ(-90deg)"}
          zIndex={50}
          padding={"0 8px"}
          onClick={() => {
            onOpen();
            modalOpenClicked();
          }}
        >
          <Image src={FacingProblemIcon} alt="" transform={"rotateZ(90deg)"} />
          <Text fontSize={"12px"} fontWeight={600} lineHeight={"14px"}>
            Facing Problems
          </Text>
        </Button>
      )}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        onOverlayClick={modalCloseClicked}
        motionPreset="slideInRight"
      >
        <ModalOverlay backgroundColor={"blackAlpha.700"} />
        <ModalContent
          backgroundColor={"zinc.800"}
          top={"10%"}
          position={"absolute"}
          right={"40px"}
          minWidth={"30rem"}
        >
          <ModalHeader
            display={"flex"}
            gap={"1rem"}
            borderBottom="0.5px solid"
            borderColor={"gray.700"}
          >
            <Image
              src={ModalFacingProblemIcon}
              alt=""
              width={"29px"}
              height={"29px"}
            />
            <Box>
              <Text
                color={"gray.50"}
                fontWeight={"500"}
                fontSize={"20px"}
                lineHeight={"24px"}
                fontFamily="Gilroy"
                fontStyle={"normal"}
              >
                Facing a problem?
              </Text>
              <Text
                fontSize={"12px"}
                fontWeight={"500"}
                lineHeight={"14px"}
                color={"gray.600"}
                fontFamily="Gilroy"
                fontStyle={"normal"}
              >
                Select the most suitable option from below to find a solution.
              </Text>
            </Box>
          </ModalHeader>
          <ModalCloseButton
            color={"zinc.500"}
            top={"1rem"}
            onClick={modalCloseClicked}
            boxShadow="none !important"
          />
          <ModalBody padding={"0 0 8px 0"}>
            <Accordion
              allowToggle
              overflowY="auto"
              height={"25.55rem"}
              sx={{
                "&::-webkit-scrollbar": {
                  width: "4px",
                  borderRadius: "20px",
                  backgroundColor: `rgba(256, 256, 256, 0.05)`,
                },
                "&::-webkit-scrollbar-thumb": {
                  borderRadius: "20px",
                  backgroundColor: `rgba(256, 256, 256, 0.5)`,
                },
              }}
            >
              {seeds.map((item, index) => (
                <Fragment key={item.id}>
                  <AccordionItem
                    color={"white"}
                    key={item.id}
                    border={0}
                    fontFamily={"Gilroy"}
                    fontStyle={"normal"}
                    fontWeight={"500"}
                  >
                    <h2>
                      <AccordionButton
                        onClick={() => {
                          modalOptionClicked(item.title);
                          window.setTimeout(() => executeScroll(), 200);
                        }}
                        _expanded={{ bg: "zinc.700", color: "white" }}
                        padding={"8px 24px 8px 24px"}
                        boxShadow="none !important"
                      >
                        <Box flex="1" textAlign="left">
                          {item.title}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      padding={"8px 36px 16px 36px"}
                      backgroundColor={"zinc.700"}
                      fontWeight={300}
                    >
                      <div ref={scrollRef} />
                      {item.message}
                    </AccordionPanel>
                  </AccordionItem>
                  {index !== seeds.length - 1 && (
                    <Divider
                      borderColor={"zinc.600"}
                      margin={"0 24px"}
                      width={"26.5rem"}
                    />
                  )}
                </Fragment>
              ))}
            </Accordion>
          </ModalBody>
        </ModalContent>
      </Modal> */}
    </>
  );
};

export default SupportButton;
