import { Box, Flex, Heading, Spinner, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { trackAnalytics } from "../../../analytics/analyticsApi";
import Logo from "../../../assets/Logo With Text.svg";
import Emoji from "../../../components/Emoji/Emoji";
import { commonProperties } from "../../../hooks/useCommonProperties";

const FeedbackComponent = () => {
  const meetingInfo = useSelector((state: any) => state.meetingInfo);
  const [isIframeReady, setisIframeReady] = useState(true);

  const handleLoad = () => {
    setisIframeReady(false);
  };

  const selectFormURL = () => {
    if (meetingInfo.role === "Mentor" && !meetingInfo.isFeedbackSubmittedByMentor) {
      return `${process.env.REACT_APP_MENTOR_DASHBOARD_URL}dashboard/feedback/${meetingInfo.packageId}`;
    }

    if (meetingInfo.role === "Mentee") {
      if (meetingInfo.sessionType.includes("Sync") && meetingInfo.sessionCount === 4) {
        return `${process.env.REACT_APP_PREPLACED_URL}dashboard/nps/${meetingInfo.menteeId}`;
      } else if (meetingInfo.sessionType.includes("Trial")) {
        return `${process.env.REACT_APP_PREPLACED_URL}feedback/trial/${meetingInfo.packageId}`;
      }
    }
  };

  useEffect(() => {
    if (document) {
      try {
        document.domain = "preplaced.in";
        // console.log("Document object set successfully")
      } catch (err) {
        // console.error("Error hai ",err)
      }
    }
  }, []);

  useEffect(() => {
    if (selectFormURL() && meetingInfo.meetingStatus === "Completed") {
      /* -------------------------------------------------------------------------- */
      /*                        Analytics: FeedbackForm Shown                       */
      /* -------------------------------------------------------------------------- */
      const reqBodyFeedbackFormShown = {
        user_id: meetingInfo.role === "Mentor" ? meetingInfo.mentorEmail : meetingInfo.menteeEmail,
        event: "Feedback Form Shown",
        properties: {
          ...commonProperties(meetingInfo),
          ...meetingInfo.device,
          role: meetingInfo.role.toLowerCase(),
        },
      };
      trackAnalytics(reqBodyFeedbackFormShown);
    }
  }, [meetingInfo.meetingStatus, selectFormURL()]);

  return (
    <>
      <Flex
        display={meetingInfo.meetingStatus === "Completed" ? "flex" : "none"}
        h="inherit"
        position="relative"
        flexDirection={{
          base: "column",
          md: "column",
          xl: "row",
          lg: "row",
          sm: "column",
        }}
        justifyContent="space-between"
        p={{ base: "5", md: "5", lg: "10" }}
        gap={{ base: "1.5rem", md: "1.5rem", sm: "1.5rem", lg: "16px" }}
      >
        <Box flex={{ base: "unset", lg: "1" }}>
          <Flex
            height={{ base: "unset", lg: "100%" }}
            flexDirection="column"
            justifyContent="space-between"
            position={{ base: "static", lg: "relative" }}
          >
            <img src={Logo} alt="" className="logo" />
            <Stack
              spacing={2}
              color="white"
              position={{ base: "static", lg: "absolute" }}
              top={{ base: "unset", lg: "50%" }}
              transform={{ base: "unset", lg: "translateY(-50%)" }}
              marginLeft={"2rem"}
            >
              <Heading as="h4" size="md" fontSize="24px">
                Hope you had a great session
                <Emoji />
              </Heading>
            </Stack>
          </Flex>
        </Box>
        {selectFormURL() && (meetingInfo.role === "Mentor" || meetingInfo.role === "Mentee") && (
          <Box background="white" p="0" overflow="hidden" flex={{ base: "1", lg: "1.5", "2xl": "1" }} borderRadius="1rem">
            {isIframeReady && (
              <div className="h-full w-full flex justify-center items-center">
                <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
              </div>
            )}
            <iframe
              title="Complete Your Profile"
              id="iframe-feedback-session"
              className="feedback-form-iframe"
              width="100%"
              height="100%"
              src={selectFormURL()}
              onLoad={handleLoad}
            />
          </Box>
        )}
      </Flex>
    </>
  );
};

export default FeedbackComponent;
