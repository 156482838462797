import { useSelector } from "react-redux";
import FeedbackComponent from "./feedback/feedback-component";
import PostSyncupFeedbackMentor from "./post-synup-feedback-mentor/post-syncup-feedback-mentor";

const FeedbackContainer = () => {
  const meetingInfo = useSelector((state: any) => state.meetingInfo);

  const { role, sessionId, mentorId, menteeId, isRecurring, sessionType, packageId } = meetingInfo;

  switch (role) {
    case "Mentor":
      if (sessionType.includes("Sync")) {
        return (
          <PostSyncupFeedbackMentor
            sessionId={sessionId}
            mentorId={mentorId}
            menteeId={menteeId}
            isRecurring={isRecurring}
            packageId={packageId}
          />
        );
      } else {
        return <FeedbackComponent />;
      }
    default:
      return <FeedbackComponent />;
  }
};

export default FeedbackContainer;
