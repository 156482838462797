import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Talk from "talkjs";
import { logger } from "../../logtail";

const useMyChatComponentHook = () => {
  const chatWindowRef = useRef(null);

  const meetingInfo = useSelector((state: any) => state.meetingInfo);

  const {
    menteeName,
    menteeId,
    menteeEmail,
    mentorName,
    mentorEmail,
    mentorId,
    role,
  } = meetingInfo;

  var meProperties: any = {
    id: role === "Mentor" ? mentorId : menteeId,
    name: role === "Mentor" ? mentorName : menteeName,
    email: role === "Mentor" ? mentorEmail : menteeEmail,
    // phone: "",
    role: role === "Mentor" ? "Mentor" : "Mentee",
  };


  var otherProperties: any = {
    id: role === "Mentor" ? menteeId : mentorId,
    name: role === "Mentor" ? menteeName : mentorName,
    email: role === "Mentor" ? menteeEmail : mentorEmail,
    // phone: 918827254360,
    role: role === "Mentor" ? "Mentee" : "Mentor",
  };

  // if (allMenteeData.photoUrl) {
  //   meProperties["photoUrl"] = allMenteeData.photoUrl;
  // }

  useEffect(() => {
    Talk.ready
      .then(async () => {
        let options: any = {};
        /* -------------------------------------------------------------------------- */
        /*                             For Identifying Me                             */
        /* -------------------------------------------------------------------------- */
        const me = new Talk.User(meProperties);
        if (!(window as any).talkSession) {
          (window as any).talkSession = new Talk.Session({
            appId: process.env.REACT_APP_TALKJS_APP_ID as string,
            me: me,
          });
        }

        /* -------------------------------------------------------------------------- */
        /*                           Other User Idenitfying                           */
        /* -------------------------------------------------------------------------- */
       

        // if (mentorPhotoUrl) {
        //   otherProperties["photoUrl"] = mentorPhotoUrl;
        // }

        const other = new Talk.User(otherProperties);

        const conversationId = mentorId + "_" + menteeId;

        const conversation = (
          window as any
        ).talkSession.getOrCreateConversation(conversationId);
        
        /* -------------------------------------------------------------------------- */
        /*                             Setting Participant                            */
        /* -------------------------------------------------------------------------- */
        conversation.setParticipant(me);
        conversation.setParticipant(other);

        /* -------------------------------------------------------------------------- */
        /*                    Select Conversation to see on the UI                    */
        /* -------------------------------------------------------------------------- */
        options = {
          selected: conversation,
        };

        let chatbox = (window as any).talkSession.createChatbox();
        
        chatbox.select(conversation);
        
        chatbox.mount(chatWindowRef.current);

        return () => {
          if (chatbox) {
            chatbox.destroy();
          }
        };
      })
      .catch(e => {
        logger.error(e);
        console.error(e);
      });
  }, []);

  return { chatWindowRef, otherProperties };
};

export default useMyChatComponentHook;
