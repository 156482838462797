import useMyChatComponentHook from "./use-my-chat-component.hook";
import "./my-chat-component.css";

interface MyChatComponentPropTypes {
  isOpen: boolean;
}

const MyChatComponent: React.FC<MyChatComponentPropTypes> = ({ isOpen }) => {
  const { chatWindowRef, otherProperties } = useMyChatComponentHook();

  return (
    <div
      className={`chat-container ${!isOpen && "hide"}`}
    //   className={`relative md:flex flex-1 flex-col bg-white rounded-tr-lg rounded-br-lg`}
    >
      <div className="flex absolute text-black flex-col w-full">
        <div className="h-[80px] flex items-center ml-24 mr-2">
          <div className="w-full">
            <h1 className="truncate">{otherProperties?.name}</h1>
          </div>
        </div>
        <div className="bg-blue-700 p-1 text-center text-white text-xs">
          <p>This chat is now unified with the dashboard</p>
        </div>
      </div>

      <div className="h-full" ref={chatWindowRef}>
        Loading...
      </div>
    </div>
  );
};

export default MyChatComponent;
