import { useState } from "react";

const usePromptCompHook = ({ variant, handlerPrimaryButton }: any) => {
  const [isMinimized, setIsMinimized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleMinimized = () => {
    setIsMinimized(!isMinimized);
  };

  const cardClassNameSelector = () => {
    switch (variant) {
      case "success":
        return "bg-emerald-100";
      case "warn":
      default:
        return "bg-amber-100";
    }
  };

  const handlePrimaryButtonClicked = async () => {
    setIsLoading(true);
    await handlerPrimaryButton();
    setIsLoading(false)
  }

  return { isMinimized, cardClassNameSelector, handleMinimized, handlePrimaryButtonClicked, isLoading };
};

export default usePromptCompHook;
