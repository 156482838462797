import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Onboarding from "../../screens/on-boarding/on-boarding";
import MeetingInit from "../../screens/meeting-ongoing/meeting-ongoing";
import FeedbackContainer from "../../screens/feedback-container/feedback-container";
import { logger } from "../../logtail";

const JoinMeeting = () => {
  // Data of Meeting like menteee/mentor Name, authToken, feedbackForm URL, role etc...
  const meetingInfo = useSelector((state: any) => state.meetingInfo);

  let params: {
    room: string;
  } = useParams();

  return (
    <>
      {!meetingInfo.authToken && meetingInfo.meetingStatus === "Pending" && (
        <Onboarding />
      )}
      {meetingInfo.authToken &&
        params.room &&
        meetingInfo.meetingStatus !== "Completed" && <MeetingInit />}
      {meetingInfo.meetingStatus === "Completed" && <FeedbackContainer />}
    </>
  );
};

export default JoinMeeting;
