import { useSelector } from "react-redux";
import { trackAnalytics } from "../../analytics/analyticsApi";
enum ButtonNamesEnum {
  SET_WEEKLU_SCHEDULE = "Set a Weekly Schedule",
  SCHEDULE_ONLY_NEXT_SESSION = "Schedule Only Next Session"
 }

const useScheduleSessionPromptHook = (hideScheduleSessionPopUp: any) => {
  const meetingInfo = useSelector((state: any) => state.meetingInfo);

  const sendAnalyticsForBothUser = (eventName: string, properties?: any) => {
    try {
      const requestBody = {
        user_id: meetingInfo?.menteeEmail,
        event: eventName,
        properties: {
          product: "Preplaced Meetings",
          mentee: {
            name: meetingInfo?.menteeName,
            id: meetingInfo?.menteeId,
            email: meetingInfo?.menteeEmail,
          },
          mentor: {
            name: meetingInfo?.mentorName,
            id: meetingInfo?.mentorId,
            email: meetingInfo?.mentorEmail,
          },
          action_by: meetingInfo?.role,
          ...properties,
        },
      };
      // Analytics for both user because of Mixpanel
      trackAnalytics(requestBody);

      requestBody.user_id = meetingInfo?.mentorEmail;
      trackAnalytics(requestBody);
    } catch (err) {
      console.error("Error In function  for event Name is", eventName, err);
    }
  };

  const handlerPrimaryButton = () => {
    sendAnalyticsForBothUser("Button Clicked", {button_name: ButtonNamesEnum.SET_WEEKLU_SCHEDULE })
    const url = `https://mentor.preplaced.in/my_mentees/${meetingInfo?.menteeId}/sessions?openScheduler=true`;
    window.open(url, "_blank");
    hideScheduleSessionPopUp();
  };

  const handlerSecondaryButton = () => {
    sendAnalyticsForBothUser("Button Clicked", {button_name: ButtonNamesEnum.SCHEDULE_ONLY_NEXT_SESSION })
    const url = "https://mentor.preplaced.in/my_sessions";
    window.open(url, "_target");
    hideScheduleSessionPopUp();
  };

  return { handlerPrimaryButton, handlerSecondaryButton };
};

export default useScheduleSessionPromptHook;
