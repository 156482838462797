import { useSelector } from "react-redux";
import stateUtilityInstance from "../../singleton/global-state";

const useCallButton = ({
  buttonClassName = "preplaced-call-button",
}: {
  buttonClassName?: string;
}) => {
  const meetingInfo = useSelector((state: any) => state.meetingInfo);
  const { role, menteeId, mentorId } = meetingInfo;
  const otherUserRecordId = role === "Mentor" ? menteeId : mentorId;
  const otherParticipantsObject: any =
    stateUtilityInstance.getPropertyByName("otherParticipants");
  const otherParticipant = otherParticipantsObject[otherUserRecordId!];

  const handleCallMentor = () => {
    const preplacedCallButton = document?.querySelector(
      `.${buttonClassName}`
    ) as HTMLElement;
    if (preplacedCallButton) {
      preplacedCallButton?.click();
    }
  };
  return {
    meetingInfo,
    otherParticipant,
    role,
    handleCallMentor,
  };
};

export default useCallButton;
