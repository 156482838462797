import React from "react";

type PostSyncupFeedbackMentorProps = {
  sessionId: string;
  mentorId: string;
  menteeId: string;
  isRecurring: boolean;
  packageId: string;
};

const PostSyncupFeedbackMentor = ({
  sessionId,
  mentorId,
  menteeId,
  isRecurring,
  packageId
}: PostSyncupFeedbackMentorProps) => {
  const url = `https://planner.preplaced.${
    process.env.REACT_APP_SERVER === "production" ? "in" : "dev"
  }/post-syncup-feedback-mentor/${sessionId}/?mentorRecordId=${mentorId}&isRecurring=${isRecurring}&menteeRecordId=${menteeId}&packageRecordId=${packageId}`;
  return (
    <div className="post-syncup-feedback-mentor">
      <iframe
        src={url}
        title="Post Syncup Feedback Mentor"
      ></iframe>
    </div>
  );
};

export default PostSyncupFeedbackMentor;
