import axios from "axios";
import * as Sentry from '@sentry/react'
/* -------------------------------------------------------------------------- */
/*                                get API call                                */
/* -------------------------------------------------------------------------- */
export const getCall = async ({ url }: any) => {
  try {
    const config = {
      url: url,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    const response = await axios(config as any);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
  }
};
/* -------------------------------------------------------------------------- */
/*                                post API call                               */
/* -------------------------------------------------------------------------- */
export const postCall = async ({ url , data , params, contentType="text/plain"}: any) => {
  try {
    const config = {
      url: url,
      method: "POST",
      headers: {
        "Content-Type":contentType,
        "Access-Control-Allow-Origin":"*",
      },
      params: params,
      data: data,
    };
    Sentry.setContext("requestData", data);
    const response = await axios(config as any);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
  }
};
