import React, { useEffect, useState } from "react";
import axios from "axios";
import minimizeIcon from "../../assets/minimize.svg";
import maximizeIcon from "../../assets/maximize.svg";
import usePromptCompHook from "./use-prompt-comp-hook";
import bellWarn from "../../assets/bellWarn.svg";
import LoaderIcon from "../../loader";
import { trackAnalytics } from "../../analytics/analyticsApi";
import { useSelector } from "react-redux";
import moment from "moment";

interface PromptComponentProp {
  title: string;
  subtitle: string;
  icon: any;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  primaryButtonClassName?: string;
  secondaryButtonClassName?: string;
  handlerPrimaryButton?: Function;
  handlerSecondaryButton?: Function;
  variant: "success" | "warn";
  children?: any;
  showMinimizedIcon?: boolean;
}

const PromptComponent: React.FC<PromptComponentProp> = ({
  title,
  subtitle,
  primaryButtonText,
  secondaryButtonText,
  primaryButtonClassName,
  secondaryButtonClassName,
  icon,
  handlerPrimaryButton,
  handlerSecondaryButton,
  variant,
  children,
  showMinimizedIcon,
}) => {
  const {
    isMinimized,
    cardClassNameSelector,
    handleMinimized,
    handlePrimaryButtonClicked,
    isLoading,
  } = usePromptCompHook({ variant, handlerPrimaryButton }); 

  return (
    <>
      {isMinimized ? (
        <div className="p-4 bg-white rounded-t-xl items-center justify-center flex absolute bottom-0 right-0 gap-4 w-[370px] md:w-[320px] h-[60px]">
          <div className="flex flex-row justify-start md:justify-between w-full pr-4 items-center">
            <div className="w-8 h-8 p-1 bg-amber-100 bg-opacity-50 rounded-full justify-center items-center inline-flex">
              <div className="grow shrink basis-0 self-stretch p-1 bg-amber-100 rounded-full justify-center items-center inline-flex">
                <div className="w-4 h-4">
                  <img src={bellWarn} alt="" className="w-4 h-4" />
                </div>
              </div>
            </div>
            <h1 className="text-slate-700 text-sm font-semibold ml-4 md:ml-0">
              Schedule your next session!
            </h1>
          </div>
          <img
            src={maximizeIcon}
            onClick={handleMinimized}
            alt="minimize_button"
            className="cursor-pointer"
          />
        </div>
      ) : (
        <div className="p-6 bg-white rounded-t-xl flex-col items-start justify-center flex absolute bottom-0 right-0 relative">
          {showMinimizedIcon && (
            <img
              src={minimizeIcon}
              alt="minimize_buttom"
              onClick={handleMinimized}
              className="cursor-pointer absolute right-4 top-5"
            />
          )}

          <div className="flex flex-row justify-between w-full">
            <div
              className={`w-12 h-12 p-1.5 bg-opacity-50 rounded-full justify-center items-center flex ${cardClassNameSelector()}`}
            >
              <div
                className={`grow shrink basis-0 self-stretch p-2 rounded-full justify-center items-center flex ${cardClassNameSelector()}`}
              >
                <div className="w-5 h-5 relative flex-col justify-start items-start flex">
                  <img src={icon} alt="Success" />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-6 mt-3">
            <h1 className="text-slate-700 mb-2 text-base font-semibold">
              {title}
            </h1>
            <p className="text-gray-500 text-sm font-normal leading-[18px]">
              {subtitle}
            </p>
            {children}
          </div>
          {secondaryButtonText && primaryButtonText && (
            <div className="flex w-full justify-between gap-2 items-center">
              <button
                className={`border-2 cursor-pointer border-gray-300 text-sm rounded-lg text-black w-[150px] md:w-[200px] h-[40px] text-center font-semibold hover:shadow ${secondaryButtonClassName}`}
                onClick={() =>
                  handlerSecondaryButton && handlerSecondaryButton()
                }
              >
                {secondaryButtonText}
              </button>
              <button
                className={`border-2 text-sm cursor-pointer rounded-lg text-white text-sm w-[150px] md:w-[200px] h-[40px] border-black bg-black font-semibold hover:shadow ${primaryButtonClassName}`}
                onClick={() =>
                  handlePrimaryButtonClicked && handlePrimaryButtonClicked()
                }
              >
                {isLoading ? <LoaderIcon /> : primaryButtonText}
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default PromptComponent;
