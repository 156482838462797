import { useSelector } from "react-redux";
import stateUtilityInstance from "../../singleton/global-state";
import { usePreplacedCallHook } from "preplaced-call-service";

export const transformResponseIntoKeyPairValue = (response: any) => {
  const storeData: any = {};
  if (!!response.length) {
    response.map((data: any, index: number) => {
      storeData[data.recordId] = data;
    });
  }
  return storeData;
};

const useOngoingMeeting = () => {
  const meetingInfo = useSelector((state: any) => state.meetingInfo);
  const { initializePreplaceCallServices } = usePreplacedCallHook();

  const createUserListForPreplacedCallService = () => {
    let selfDetails = {};
    let otherParticipant = {};

    if (meetingInfo?.role === "Mentee") {
      selfDetails = {
        name: meetingInfo?.menteeName,
        email: meetingInfo?.menteeEmail,
        picture: meetingInfo?.menteePhotoUrl,
        role: "Mentee",
        recordId: meetingInfo?.menteeId,
        phoneNumber: meetingInfo?.menteePhoneNumber,
        isPhoneVerified: false,
      };
      otherParticipant = {
        name: meetingInfo?.mentorName,
        email: meetingInfo?.mentorEmail,
        picture: meetingInfo?.mentorPhotoUrl,
        role: "Mentor",
        isPhoneVerified: false,
        // meetingId: mentorDetails?.dyteMeetingId,
        conversationId: `${meetingInfo?.mentorId}_${meetingInfo.menteeId}`,
        packageRecordId: meetingInfo?.packageId,
        recordId: meetingInfo?.mentorId,
        phoneNumber: meetingInfo?.mentorPhoneNumber,
      };
    } else if (meetingInfo?.role === "Mentor") {
      selfDetails = {
        name: meetingInfo?.mentorName,
        email: meetingInfo?.mentorEmail,
        picture: meetingInfo?.mentorPhotoUrl,
        role: "Mentor",
        recordId: meetingInfo?.mentorId,
        phoneNumber: meetingInfo?.mentorPhoneNumber,
        isPhoneVerified: false,
      };
      otherParticipant = {
        name: meetingInfo?.menteeName,
        email: meetingInfo?.menteeEmail,
        picture: meetingInfo?.menteePhotoUrl,
        role: "Mentee",
        isPhoneVerified: false,
        // meetingId: mentorDetails?.dyteMeetingId,
        conversationId: `${meetingInfo?.mentorId}_${meetingInfo.menteeId}`,
        packageRecordId: meetingInfo?.packageId,
        recordId: meetingInfo?.menteeId,
        phoneNumber: meetingInfo?.menteePhoneNumber,
      };
    }

    const otherParticipants = [otherParticipant];
    stateUtilityInstance.setPropertyValue(
      "otherParticipants",
      transformResponseIntoKeyPairValue(otherParticipants)
    );
    initializePreplaceCallServices(
      process.env.REACT_APP_ABLY_KEY as string,
      otherParticipants,
      selfDetails
    );
  };
  createUserListForPreplacedCallService();
  return {};
};

export default useOngoingMeeting;
