import {
    Box
  } from "@chakra-ui/react";
  import PreplacedLogo from "../../assets/Logo With Text.svg";
const Navbar = () => {
    return(
        <Box p="5">
            <img src={PreplacedLogo} alt="Preplaced logo" className="logo" />
          </Box>
    );
}

export default Navbar;