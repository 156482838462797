import React from "react";
import PromptComponent from "../prompt-comp/prompt-comp";
import GreenCheck from "../../assets/RoundGreenCheck.svg";
import { ReactComponent as WhiteStar } from "../../assets/WhiteStar.svg";
import { ReactComponent as FilledStar } from "../../assets/YellowStar.svg";
import useFeedbackTrial from "./index.hook";
import { useSelector } from "react-redux";
import { trackAnalytics } from "../../analytics/analyticsApi";
import { commonProperties } from "../../hooks/useCommonProperties";
import { setCookie } from "../../utils/setCookie";

const FeedbackTrial = ({meeting}: any) => {
  const {
    isFeedbackCanceled,
    isFeedbackSubmitted,
    handleCancelFeedback,
    handleFeedbackSubmit,
    handleTextareaChange,
    textareaValue,
    filledStarsVideo,
    handleFilledStarsVideo,
    filledStarsAudio,
    handleFilledStarsAudio,
  } = useFeedbackTrial();

  const meetingInfo = useSelector((state: any) => state.meetingInfo);

  const handlerPrimaryButton = () => {
    const reqBodyWhiteBoard = {
      user_id: meetingInfo.menteeEmail,
      event: "Dyte Rating Submitted",
      properties: {
        ...commonProperties(meetingInfo),
        action_by: meetingInfo.role,
        audio_rating: filledStarsAudio,
        video_rating: filledStarsVideo,
        reasons: textareaValue,        
      },
    };
    trackAnalytics(reqBodyWhiteBoard);
    meeting.leaveRoom();
    setCookie(meetingInfo?.sessionId, true, 3);
  }

  const handlerSecondaryButton = () => {
    const reqBodyWhiteBoard = {
      user_id: meetingInfo.menteeEmail,
      event: "Dyte Rating Cancel",
      properties: {
        ...commonProperties(meetingInfo),
        action_by: meetingInfo.role,
      },
    };
    trackAnalytics(reqBodyWhiteBoard);
    meeting.leaveRoom();
    setCookie(meetingInfo?.sessionId, true, 3);
  }

  return (
    <div className="absolute right-8 w-[370px] bottom-16 z-10">
      <PromptComponent
        title={`We are trying to improve our meetings platform by collecting your feedback.`}
        subtitle="This would help us gain invaluable feedback from your end which could improve your overall experience."
        icon={GreenCheck}
        primaryButtonText={`Submit and Leave`}
        secondaryButtonText="Cancel and Leave"
        handlerPrimaryButton={handlerPrimaryButton}
        handlerSecondaryButton={handlerSecondaryButton}
        variant="success"
      >
        <div className="flex w-[340px] items-start justify-between mt-6">
          <div className="flex w-[156px] flex-col gap-2">
            <div className="self-stretch text-sm font-semibold leading-5 text-[#344054]">
              Video Rating
            </div>
            <div className="flex items-start gap-2">
              {Array.from({ length: 5 }).map((_, index) => (
                <span
                  key={index}
                  className="cursor-pointer"
                  onClick={() => handleFilledStarsVideo(index)}
                >
                  {index < filledStarsVideo ? <FilledStar /> : <WhiteStar />}
                </span>
              ))}
            </div>
          </div>

          <div className="-start flex w-[156px] flex-col gap-2">
            <div className="self-stretch text-sm font-semibold leading-5 text-[#344054]">
              Audio Rating
            </div>
            <div className="flex items-start gap-2">
              {Array.from({ length: 5 }).map((_, index) => (
                <span
                  key={index}
                  className="cursor-pointer"
                  onClick={() => handleFilledStarsAudio(index)}
                >
                  {index < filledStarsAudio ? <FilledStar /> : <WhiteStar />}
                </span>
              ))}
            </div>
          </div>
        </div>
        {(filledStarsAudio >= 1 && filledStarsAudio <= 3) ||
        (filledStarsVideo >= 1 && filledStarsVideo <= 3) ? (
          <div
            className={`flex
          flex-col gap-2 mt-6`}
          >
            <p className="text-sm font-semibold leading-5 text-[#344054]">
              Let us know the problems faced
            </p>
            <textarea
              className="h-24 p-2 w-full resize-none rounded-lg border border-[#d0d5dd] bg-white text-[.9375rem] leading-6 text-black"
              placeholder="Write your reason here... "
              value={textareaValue}
              onChange={handleTextareaChange}
            />
          </div>
        ) : null}
      </PromptComponent>
    </div>
  );
};

export default FeedbackTrial;
