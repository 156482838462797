import { postCall } from "../apiServices/apiServices";

/* -------------------------------------------------------------------------- */
/*                        Track Mentor Mentee Analytics                       */
/* -------------------------------------------------------------------------- */
const contentType = "application/json";

// Common Analytics
export const trackAnalytics = async (reqBody: any) => {
  try {
    if (document.location.hostname !== "localhost") {
      const response = await postCall({
        url:
          process.env.REACT_APP_COMMON_TRACK_ANALYTICS ||
          "https://common-api.preplaced.in/analytics/track",
        data: {
          user_id: reqBody.user_id,
          event: reqBody.event,
          properties: reqBody.properties,
        },
        contentType: contentType,
      });

      (window as any).posthog &&
        (window as any).posthog.capture(reqBody.event, reqBody.properties);

      return response;
    } else {
      const response = await postCall({
        url: "https://common-api.preplaced.dev/analytics/track",
        data: {
          user_id: reqBody.user_id,
          event: reqBody.event,
          properties: reqBody.properties,
        },
        contentType: contentType,
      });

      (window as any).posthog &&
        (window as any).posthog.capture(reqBody.event, reqBody.properties);

      return response;
    }
  } catch (error) {
    console.error(error);
  }
};

/* -------------------------------------------------------------------------- */
/*                     Identify Mentor & Mentee Analytics                     */
/* -------------------------------------------------------------------------- */

// identify Mentor Analytics
export const identifyMentorAnalytics = async (reqBody: any) => {
  try {
    const response = await postCall({
      url: process.env.REACT_APP_IDENTIFY_MENTOR_ANALYTICS,
      data: {
        user_id: reqBody.mentor_id,
        traits: reqBody.mentor_traits,
      },
      contentType: contentType,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

// identify Mentee Analytics
export const identifyMenteeAnalytics = async (reqBody: any) => {
  try {
    const response = await postCall({
      url: process.env.REACT_APP_IDENTIFY_MENTEE_ANALYTICS,
      data: {
        user_id: reqBody.mentee_id,
        traits: reqBody.mentee_traits,
      },
      contentType: contentType,
    });
    console.log("identifyMenteeAnalytics", response);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const identifyMentorMenteeAnalytics = async (reqBody: any) => {
  try {
    identifyMentorAnalytics(reqBody);
    identifyMenteeAnalytics(reqBody);
  } catch (error) {
    console.error(error);
  }
};
